import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {SuggestionResponseItem, SuggestionResponse, SuggestionRequest,SuggestionPutRequest} from './Request/Suggestions';

const SuggestionApi = Api.injectEndpoints({
    endpoints: build => ({
        postSuggestion: build.mutation< SuggestionResponseItem, [Partial<SuggestionResponseItem>]>({
            query: ([Suggestion]) => ({
                url: 'api/suggestion',
                method: 'POST',
                body: JSON.stringify({...Suggestion})
            })
        }),

        getSuggestions: build.query<SuggestionResponse, void>({
            query: () => (`/api/public/suggestion`),
            transformResponse: Hydra.MultiResponseTransformer
        }),

        getSuggestion: build.query<SuggestionResponseItem, SuggestionRequest>({
            query: ({ id }) => (`/api/suggestion/${ id }`),
            transformResponse: Hydra.SingleResponseTransformer
        }),

        deleteSuggestion: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/suggestion/${ id }`,
                method: 'DELETE'
            })
        }),
        putSuggestion: build.mutation<SuggestionResponseItem,[string, SuggestionPutRequest]>({
            query: ([id, organisation]) => ({
                url: '/api/suggestion/' + id,
                method: 'PUT',
                body: JSON.stringify(organisation)
            })
        }),
    }),
});

export const {
    useGetSuggestionQuery,
    useGetSuggestionsQuery,
    useDeleteSuggestionMutation,
    usePostSuggestionMutation,
    usePutSuggestionMutation,
} = SuggestionApi;
export default SuggestionApi;
