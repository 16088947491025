import { useEffect, useState } from "react";
import info from "../../../../Assets/info.svg";
import Button from "../../../Shared/Form/Button";
import { useTranslation } from "react-i18next";
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import Mapper from "./MapperComponents/Mapper";
import FileError from "./MapError/FileError";
import ErrorScreenWidth from "./MapperComponents/ErrorScreenWidth";
import Loader from "../../../Shared/Loader";
import { motion } from "framer-motion";
import AlertModal from "./AlertModal";
import { Trans } from 'react-i18next';
import { useMapperContext } from "./Context/MapperContext";
import { useChat } from '../../PatrickAssistant/ChatContext';
import Constants from "../../../../Constants";
import { useAppSelector } from "../../../../Redux/store";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";

function Mapping() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { handleExtract, setIsFileError, setShowData, showData, setIsAlertModalOpen, csvData, errorsFound, setAlertMessage, setAlertTitle, isAlertModalOpen, isFileError, isExtractingFields, extractFieldError, fileErrorCheck, handleErrorDisplay, failedError, alertMessage, alertTitle, handleCheck, handleBackToMap, handleCloseModal, handleShowAlert, handleFileUpload } = useMapperContext();
  const { toggleChat, addMessage, isChatOpen } = useChat();
  const org_id = useAppSelector((s) => s.organisation.id)!;
  const [isAtTop, setIsAtTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [activeField, setActiveField] = useState<boolean>(false);

  
  useEffect(() => {
    handleCheck();
  }, []);

  useEffect(() => {
    if (window.location.hash === '#errors') {
      setIsFileError(true);
    } else if (window.location.hash === "#editor") {
      setIsFileError(true);
    } else {
      handleExtract();
    }
  }, []);

  useEffect(() => {
    const handleScrollAndResize = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      setIsAtTop(scrollTop === 0);
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
    };

    window.addEventListener("scroll", handleScrollAndResize);
    window.addEventListener("resize", handleScrollAndResize);

    handleScrollAndResize();

    return () => {
      window.removeEventListener("scroll", handleScrollAndResize);
      window.removeEventListener("resize", handleScrollAndResize);
    };
  }, [isExtractingFields]);

  const openInfo = () => {
    setShowData(!showData);
  };

  function handleReUpload() {
    navigate(`/${i18n.language}/dashboard/cards/import`);
    toggleChatVisibility();
  }

  const toggleChatVisibility = () => {
    if (!isChatOpen) {
      toggleChat();
    }
    const systemMessage = {
      sender: 'assistant',
      content: 'upload : true (' + i18n.language + ')',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
    addMessage(systemMessage);
  };

  async function navigateToPreview() {
    const relevantErrors = errorsFound.filter(
      (error) => ["1", "2", "4", "5"].includes(String(error.column))
    );

    if (relevantErrors.length > 0) {
      setAlertTitle(t("mapping:stillErrorsToFix") as string);
      setAlertMessage(t("mapping:fixErrorsBeforeProceeding") as string);
      handleShowAlert();
      return;
    }

    if (csvData) {
      const data = csvData[1];
      if (await handleFileUpload()) {
        navigate(`/${i18n.language}/dashboard/cards/import/preview`, { state: { data } });
      }
    }
  }

  function arrayToCSV(array: string[][]): string {
    return array
      .map(row => row.map(item => {
        const escapedItem = item.replace(/"/g, '""');
        return `"${escapedItem}"`;
      }).join(','))
      .join('\n');
  }

  const handleMouseEnter = (direction: string) => {
    if (direction === 'up') {
      window.scrollBy({ top: -25, behavior: 'smooth' });
    } else if (direction === 'down') {
      window.scrollBy({ top: 25, behavior: 'smooth' });
    }
  };

  const checkActiveFieldInSessionStorage = () => {
    const activeFieldValue = sessionStorage.getItem("activeField");
    setActiveField(!!activeFieldValue);
  };

  useEffect(() => {
    checkActiveFieldInSessionStorage();

    const interval = setInterval(() => {
      checkActiveFieldInSessionStorage();
    }, 50);

    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <div
        className={`fixed top-0 -ml-10 w-[calc(100%-20rem)] bg-gradient-to-b from-gray-400 text-white font-bold text-lg p-4 flex justify-center z-50 transition-all duration-500 ${!activeField ? 'transform -translate-y-full' : isAtTop ? 'transform -translate-y-full' : ''}`}
        onDragOver={() => handleMouseEnter('up')}
      >
        <p className="bg-black rounded-2xl w-fit px-5">{t('mapping:dragUp')}</p>
      </div>

      <div
        className={`fixed bottom-0 -ml-10 w-[calc(100%-20rem)] bg-gradient-to-t from-gray-400 text-white font-bold text-lg p-4 flex justify-center z-50 transition-all duration-500 ${!activeField ? 'transform translate-y-full' : isAtBottom ? 'transform translate-y-full' : ''}`}
        onDragOver={() => handleMouseEnter('down')}
      >
        <p className="bg-black rounded-2xl w-fit px-5">{t('mapping:dragDown')}</p>
      </div>

      <ErrorScreenWidth />
      {isAlertModalOpen && <AlertModal isOpen={isAlertModalOpen} onClose={handleCloseModal} alertMessage={alertMessage} alertTitle={alertTitle} />}
      <h1 className="mb-2.5 font-semibold text-[24px] hidden 2xl:block">{t('mapping:mapping')}</h1>
      <div className="min-w-[1125px] bg-white border border-[#ECF1F8] p-[20px] mb-[20px] hidden 2xl:block">
        <div className="bg-[#F5F8FB] border border-[#3273F6] opacity-100 p-[15px] cursor-pointer" onClick={openInfo}>
          <div className="flex flex-row gap-1.5 font-medium">
            <img src={info} alt="info" />
            <p>{t('mapping:comments')}{showData ? '...' : ''}</p>
          </div>
          <motion.div animate={(showData) ? { height: '0' } : { height: 'auto' }} className="overflow-hidden">
            {!isFileError ? (
              <p className="mt-1 text-md">
                <Trans i18nKey="mapping:infoMapping" components={{ br: <br /> }} />
              </p>
            ) : isFileError ? (
              <p className="mt-1 text-md">
                <Trans i18nKey="mapping:infoChecker" components={{ br: <br /> }} />
              </p>
            ) : null}
          </motion.div>
        </div>
        {isExtractingFields ?
          <div className="flex flex-col justify-center text-center pt-10">
            <Loader />
            <h2 className="text-lg font-bold mt-10">{!fileErrorCheck ? t("mapping:loadingMap") : t("mapping:checkingErrors")}</h2>
          </div> :
          <>
            {!isFileError ? (
              <>
                {extractFieldError === null &&
                  <Mapper />}
                {extractFieldError !== null && <h2 className="text-center font-bold my-6 p-4 text-xl bg-red-500 text-white w-[100%]">{extractFieldError}</h2>}
              </>
            ) : (
              failedError === null ?
                <FileError /> : <h2 className="text-center font-bold my-6 p-4 text-xl bg-red-500 text-white w-[100%]">{failedError}</h2>
            )}

            
              {isFileError ? (
                <div className="flex flex-row justify-between border-t border-[#414141] pt-5">
                  <div className="flex flex-row gap-[10px]">
                  {window.location.hash !== '#errors' &&
                    <Button title={t('mapping:back')} className="bg-[#F4F8FB] hover:text-white hover:border-none" icon={faArrowLeft} action={handleBackToMap} />
                  }
                  <Button title={t('mapping:reupload')} className="text-map-blue hover:text-white hover:border-none" icon={faRotateRight} action={handleReUpload} />
                  </div>
                  <div className="flex flex-row">
                  <Button title={t('mapping:next')} className="bg-map-blue hover:bg-gray-400" action={navigateToPreview} />
                  </div>
                  </div>
              ) : (
                extractFieldError === null 
                ? <div className="flex flex-row justify-end border-t border-[#414141] pt-5"><Button title={t('mapping:next')} className="bg-map-blue hover:bg-gray-400" action={(e) => { e.stopPropagation(); handleErrorDisplay(e);}} /></div>
                : <div className="flex flex-row justify-end border-t border-[#414141] pt-5"><Button title={t('mapping:reupload')} className="text-map-blue hover:text-white hover:border-none" icon={faRotateRight} action={handleReUpload} /></div>
              )}
            
          </>}
      </div>
    </>
  );
}

export default Mapping;
