import DynamicListRequest from "../Request/DynamicListRequest";
import LoadingPage from "../../../Components/Pages/LoadingPage";
import * as React from "react";

/**
 * Helper class to build the filtered urls for the API
 */
class UrlHelper
{
    /**
     * @param baseUrl
     * @param requestOptions
     */
    public buildUrl(baseUrl: string, requestOptions: DynamicListRequest): string
    {
        const query: Array<string> = [];

        const organisation = requestOptions.id;

        // Filtering
        if (requestOptions.filter && 0 < requestOptions.filter.length) {
            requestOptions.filter.forEach((filter: { field: any; value: any; }) => {
                query.push(`${filter.field}=${filter.value}`);
            });
        }

        // Pagination
        if (requestOptions.page) {
            query.push(`page=${requestOptions.page}`);
        }

        if (requestOptions.pageSize) {
            query.push(`pageSize=${requestOptions.pageSize}`);
        }

        // Sorting
        if (requestOptions.sort && 0 < requestOptions.sort.length) {
            requestOptions.sort.forEach((sort: { field: any; sort: any; }) => {
                query.push(`order[${sort.field}]=${sort.sort}`);
            });
        }

        return (0 < query.length ? `${baseUrl}/${organisation}/cards?${query.join('&')}` : baseUrl);
    }
}

export default new UrlHelper();
