import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {AddLogoRequest} from './Request/AddLogo';
import {CardRequest, CardsResponse} from './Request/Cards';
import {NewsResponse} from './Request/News';
import {NewsFeedRequest} from './Request/NewsFeed';
import {NotificationRequest} from './Request/Notification';
import {
    CardInfoPutRequest, CardInfoRequest,
    OrganisationPostRequest,
    OrganisationPutRequest,
    OrganisationRequest,
    OrganisationResponse,
    OrganisationResponseItem,
    OrganisationSettingsRequest,
    OrganisationSettingsResponse, SettingsRequest, SendReminderResponse
} from './Request/Organisations';
import {ScanResponse} from './Request/Scan';
import {SocialsRequest} from './Request/Socials';
import {ThemeRequest} from './Request/Theme';
import {TypesRequest, TypesResponse} from './Request/Types';
import {UsersResponse} from './Request/User';
import {SponsorRequest, SponsorResponse} from "./Request/Sponsors";
import DynamicListRequest from "./Request/DynamicListRequest";
import UrlHelper from "./Helpers/UrlHelper";

const organisationApi = Api.injectEndpoints({
    endpoints: build => ({
        postOrganisation: build.mutation<OrganisationResponseItem, OrganisationPostRequest>({
            query: (organisation) => ({
                url: '/api/organisations',
                method: 'POST',
                body: JSON.stringify(organisation)
            })
        }),

        putOrganisation: build.mutation<OrganisationResponseItem,[string, OrganisationPutRequest]>({
            query: ([id, organisation]) => ({
                url: '/api/organisations/' + id,
                method: 'PUT',
                body: JSON.stringify(organisation)
            })
        }),

        importCards: build.mutation<{ successfulRecords: number; duplicates: string[] }, [string, string, boolean, string]>({
            query: ([org_id, file_id, send, activationEmailDate]) => ({
                url: `/api/organisations/${ org_id }/import`,
                method: 'POST',
                //body: JSON.stringify({id: file_id})
                body: ({ send: send, id: file_id, activationEmailDate: activationEmailDate})
            })
        }),

        putTheme: build.mutation<void, ThemeRequest>({
            query: ({ id, settings }) => ({
                url: `/api/organisations/${ id }/settings/theme`,
                method: 'PUT',
                body: JSON.stringify({ settings })
            })
        }),

        putNewsFeed: build.mutation<void, NewsFeedRequest>({
            query: ({ id, feed }) => ({
                url: `/api/organisations/${ id }/settings/newsfeed`,
                method: 'PUT',
                body: JSON.stringify({ settings: { feed } })
            })
        }),

        pullFeed: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/organisations/${ id }/pullfeed`,
                method: 'POST'
            })
        }),

        addLogo: build.mutation<OrganisationResponseItem, AddLogoRequest>({
            query: ({ organisation_id, upload_id }) => ({
                url: `/api/organisations/${organisation_id}/add-logo`,
                method: 'POST',
                body: JSON.stringify({ file: upload_id })
            })
        }),

        putSocials: build.mutation<void, SocialsRequest>({
            query: ({ id, socials }) => ({
                url: `/api/organisations/${ id }/settings/socials`,
                method: 'PUT',
                body: JSON.stringify({ settings: socials })
            })
        }),

        getScans: build.query<Array<number>, { org_id: string; fromDate: string; toDate: string }>({
            query: ({ org_id, fromDate, toDate }) => ({
                url: `/api/organisations/${org_id}/scans`,
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                },
            }),
        }),

        getCheckedInScans: build.query<ScanResponse, { org_id: string; fromDate: string; toDate: string }>({
            query: ({ org_id, fromDate, toDate }) => ({
                url: `/api/organisations/${org_id}/checked-in-scans`,
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                },
            }),
        }),

        getCheckOutScans: build.query<ScanResponse, { org_id: string; fromDate: string; toDate: string }>({
            query: ({ org_id, fromDate, toDate }) => ({
                url: `/api/organisations/${org_id}/check-out-scans`,
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                },
            }),
        }),

        getCheckScans: build.query<ScanResponse, { org_id: string; fromDate: string; toDate: string }>({
            query: ({ org_id, fromDate, toDate }) => ({
                url: `/api/organisations/${org_id}/check-scans`,
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                },
            }),
        }),

        getCheckedInScansToday: build.query<number, string>({
            query: (id) => (`/api/organisations/${ id }/checked-in-scans-today`),
        }),

        getCheckedOutScansToday: build.query<number, string>({
            query: (id) => (`/api/organisations/${ id }/checked-out-scans-today`),
        }),

        getUsers: build.query<UsersResponse, string>({
            query: (id) => (`/api/organisations/${ id }/users`),
        }),

        getOrganisations: build.query<OrganisationResponse, {hidden: string}>({
            query: ({ hidden }) => ({
                url: `/api/public/organisations`,
                params: {
                    hidden,
                },
            }),
            transformResponse: Hydra.MultiResponseTransformer,
        }),


        getOrganisationCards: build.query<CardsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl(`/api/organisations`, request)
        }),

        getOrganisation: build.query<OrganisationResponseItem, OrganisationRequest>({
            query: ({ id }) => (`/api/organisations/${ id }`),
            transformResponse: Hydra.SingleResponseTransformer
        }),

        getOrganisationTypes: build.query<TypesResponse, string>({
            query: (id) => (`/api/organisations/${ id }/types`),
            //transformResponse: Hydra.MultiResponseTransformer
        }),

        getNews: build.query<NewsResponse, string>({
            query: (id) => (`/api/organisations/${ id }/news`),
        }),

        postNotification: build.mutation<void, NotificationRequest>({
            query: (request) => ({
                url: `api/organisations/${ request.id }/send-notification`,
                method: 'POST',
                body: JSON.stringify(request)
            })
        }),

        exportCards: build.mutation<string, { org_id: string, filters: Array<{ field: string, value: string }>, page: number, pageSize: number, orderKey: string, orderValue: string }>({
            query: ({ org_id, filters, page, pageSize, orderKey, orderValue }) => {
                const queryParams = filters.map(filter => (
                    `${encodeURIComponent(filter.field)}=${encodeURIComponent(filter.value)}`
                )).join('&');

                return {
                    url: `/api/organisations/${org_id}/export?${queryParams}&page=${page}&pageSize=${pageSize}&order[${orderKey}]=${orderValue}`,
                    method: 'GET'
                };
            }
        }),

        getSponsorData: build.query<SponsorResponse, SponsorRequest>({
            query: ({id}) => (`/api/organisations/${ id }/sponsors`),
        }),

        getTypeData: build.query<TypesResponse, TypesRequest>({
            query: ({id}) => (`/api/organisations/${ id }/types`),
        }),

        checkOutCards: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/organisations/${ id }/checkout-cards`,
                method: 'POST'
            })
        }),

        getOrganisationSettings: build.query<OrganisationSettingsResponse, string>({
            query: (id) => (`/api/organisations/${ id }/settings` )
        }),

        putSettings: build.mutation<void, SettingsRequest>({
            query: ({ id, notification, sponsor, patrickAI, scan, photo, familyCards, maxNumberOfPeople }) => ({
                url: `/api/organisations/${ id }/settings/feature`,
                method: 'PUT',
                body: JSON.stringify({ notification, sponsor, patrickAI, scan, photo, familyCards, maxNumberOfPeople })
            })
        }),

        putCardInfo: build.mutation<OrganisationResponseItem, CardInfoRequest>({
            query: ({id, cardInfo, name, displayMode}) => ({
                url: `/api/organisations/${ id }/settings/cardinfo`,
                method: 'PUT',
                body: JSON.stringify({cardInfo, name, displayMode})
            })
        }),

        sendReminderMail: build.mutation<SendReminderResponse, string>({
            query: (id) => ({
                url: `/api/organisations/${ id }/send-reminder`,
                method: 'POST'
            })
        }),
    }),
});

export const {
    useGetOrganisationsQuery,
    useGetOrganisationQuery,
    useLazyGetOrganisationQuery,
    useLazyGetOrganisationsQuery,
    usePutThemeMutation,
    usePutSocialsMutation,
    useAddLogoMutation,
    useGetOrganisationCardsQuery,
    useLazyGetOrganisationCardsQuery,
    useGetOrganisationTypesQuery,
    useLazyGetOrganisationTypesQuery,
    usePostOrganisationMutation,
    usePutOrganisationMutation,
    useImportCardsMutation,
    useGetNewsQuery,
    useLazyGetNewsQuery,
    usePutNewsFeedMutation,
    usePullFeedMutation,
    usePostNotificationMutation,
    useLazyGetScansQuery,
    useLazyGetCheckedInScansQuery,
    useLazyGetCheckOutScansQuery,
    useLazyGetCheckScansQuery,
    useLazyGetCheckedInScansTodayQuery,
    useLazyGetCheckedOutScansTodayQuery,
    useLazyGetUsersQuery,
    useExportCardsMutation,
    useLazyGetSponsorDataQuery,
    useLazyGetTypeDataQuery,
    useCheckOutCardsMutation,
    useLazyGetOrganisationSettingsQuery,
    usePutSettingsMutation,
    usePutCardInfoMutation,
    useSendReminderMailMutation,
} = organisationApi;
export default organisationApi;
