import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {PropsWithChildren} from "react";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    flexDirection: 'row-reverse',
    '&:hover': {
        background: '#F4F8FB',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type AppAccordionProps = PropsWithChildren<{
    title?: string | null,
    subTitle?: string | null,
    headerElement?: React.ReactNode | null,
    expanded?: boolean,
    defaultExpanded?: boolean,
}>;

export default function AppAccordion({children, headerElement, title, subTitle, ...props}: AppAccordionProps) {
    return (
        <Accordion {...props}>
            <AccordionSummary>
                {headerElement && (<>{headerElement}</>)}
                {title && (
                    <Typography sx={{width: subTitle ? '33%' : 'auto', flexShrink: subTitle ? 0 : 1, fontFamily: 'Inter'}}>
                    {title}
                    </Typography>
                )}
                {subTitle && (
                    <Typography>{subTitle}</Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
