import { useTranslation } from "react-i18next";
import errorImg from "../../../../../Assets/error-round.svg";
import warningImg from "../../../../../Assets/warning-round.svg";
import { ErrorRowProps } from "../types";

function ErrorRow({ index, error, errors }: ErrorRowProps) {
  const { t } = useTranslation();

  return (
    <div className={`flex w-full py-4 gap-2 min-h-[20px] items-center text-sm ${errors.length !== index + 1 ? "border-b border-gray-300" : ""}`}>
      {error.column == 1 || error.column == 2 || error.column == 4 || error.column == 5 ? <img src={errorImg} alt="error image" className="W-[2%]" /> : <img src={warningImg} alt="warning image" className="W-[2%]" />}
      <p className="basis-[5%]">{`${index + 1}.`}</p>
      <p className="basis-[10%]">{`${t('mapping:row')} ${error.row}`}</p>
      <p className="basis-[10%]">{`${t('mapping:column')} ${error.column}`}</p>
      <p className="basis-[20%]">{error.field}</p>
      <p className="flex-grow">{error.issue}</p>
    </div>
  );
}

export default ErrorRow
