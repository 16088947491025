import { faCheck, faEdit, faTrashAlt, faQrcode, faHandPointer, faInfinity } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '../Modal/Modal'
import { TableContext } from './Table'
import { useLazyGetOrganisationSettingsQuery } from '../../../Redux/Api/Organisations'
import { useAppSelector } from '../../../Redux/store'
import { useTranslation } from "react-i18next";
export interface RowProps extends HTMLMotionProps<"tr"> {
    data: Array<any>,
    index: number,
    indexKey?: number | string,
    editUrl?: string,
    action?: () => void
    errorAware?: boolean,
    downloadQr?: () => void
    deleteAction?: (id: string) => void
    deleteConfirmMessage?: string,
}

const Row = (props: RowProps) => {

    const { t } = useTranslation()

    const ctx = useContext(TableContext)

    const modalContext = useContext(ModalContext)

    const search = ctx.search

    const navigate = useNavigate()

    const { data, children, index, indexKey, editUrl, downloadQr, deleteAction, deleteConfirmMessage, action, errorAware, ...restProps } = props

    const org_id = useAppSelector((s) => s.organisation.id)!

    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()

    useEffect(() => {
        if (org_id) getOrganisationsSettings(org_id)
    }, [org_id])

    var newData = []

    if (settings?.familyCards === false) {
        for (let i = 0; i < data.length; i++) {
            if (i !== 11) {
                newData.push(data[i]);
            }
        }
    } else {
        newData = data
    }

    const rowItem = (v: string, i: number) => {
        if (v === "01-01-9999") {
            return (
                <td onClick={addToSelection} className={`${action ? 'cursor-pointer' : ''} whitespace-nowrap overflow-hidden text-ellipsis max-w-[5rem]`} key={i}>
                    <FontAwesomeIcon icon={faInfinity} />
                </td>
            );
        }
    
        if (!(v || '').toString().toLocaleLowerCase().includes(search.toLowerCase())) {
            return (
                <td onClick={addToSelection} className={`${action ? 'cursor-pointer' : ''} whitespace-nowrap overflow-hidden text-ellipsis max-w-[5rem]`} key={i}>{v}</td>
            )
        }
    
        if ((v || '').length <= 0 && errorAware) {
            return (
                <td
                    onClick={addToSelection}
                    className={`${action ? 'cursor-pointer' : ''} whitespace-nowrap text-red-400 font-bold overflow-hidden text-ellipsis max-w-[5rem]`}
                    key={i}
                >
                    {t("cards:list:noData")}
                </td>
            );
        }
    
        let middle = (v || '').toString().toLowerCase().indexOf(search.toLowerCase())
    
        return (
            <td onClick={addToSelection} className={`${action ? 'cursor-pointer' : ''} whitespace-nowrap overflow-hidden text-ellipsis max-w-[5rem]`} key={i}>
                {(v || '').toString().slice(0, middle)}
                <span className='text-yellow-500'>{(v || '').toString().slice(middle, middle + search.length)}</span>
                {(v || '').toString().slice(middle + search.length)}
            </td>
        )
    }
    

    const addToSelection = () => {
        action && action()
        if (!ctx.withSelectionHeader) return

        ctx.setSelection((cur) => {
            let cp = { ...cur }

            if (cur[index]) {
                delete cp[index]
            } else {
                cp[index] = indexKey
            }

            return cp
        })
    }

    const deleteButton = () => {
        if (deleteAction)
            modalContext.withModal({ title: t("areYouSure"), body: deleteConfirmMessage || t("confirmDeletion ") }, deleteAction)()
    }

    const downloadQrCode = () => {
        if (downloadQr) downloadQr()
    }

    return (
        <tr className='bg-light-200 hover:bg-gray-100 h-10 relative'>
            {ctx.withSelectionHeader && (
                <td className={`h-10 w-10 ${action ? 'cursor-pointer' : ''}`}>
                    <div onClick={addToSelection} className='ml-1 h-6 w-6 bg-light-300 flex items-center justify-center rounded-md'>
                        <motion.div initial={{ scale: 0 }} animate={{ scale: ctx.selection[index] ? 1 : 0 }}>
                            <FontAwesomeIcon className='scale-75' icon={faCheck} />
                        </motion.div>
                    </div>
                    <AnimatePresence>
                        {ctx.selection[index] && <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} initial={{ maxWidth: '0%' }} exit={{ maxWidth: '0%' }} animate={{ maxWidth: '100%' }} className='absolute left-0 top-0 w-full h-[100%] bg-accent opacity-[8%] pointer-events-none' />}
                    </AnimatePresence>
                </td>
            )}

            {newData.map(rowItem)}

            {action && (
                <td onClick={addToSelection} className='!w-[4em] text-center py-1 text-sm cursor-pointer bg-accent transition-colors text-white hover:bg-accent-light'>
                    <FontAwesomeIcon icon={faHandPointer} />
                </td>
            )}

            {downloadQr && (
                <td
                    className='!w-[4em] text-center py-1 text-sm cursor-pointer bg-accent transition-colors text-white hover:bg-accent-light'
                    onClick={downloadQrCode}>
                    <FontAwesomeIcon icon={faQrcode} />
                </td>
            )}

            {editUrl && (
                <td
                    className='!w-[4em] text-center py-1 text-sm cursor-pointer bg-accent transition-colors text-white hover:bg-accent-light'
                    onClick={() => editUrl && navigate(editUrl)}>
                    <FontAwesomeIcon icon={faEdit} />
                </td>
            )}

            {deleteAction && (
                <td
                    className='!w-[4em] text-center py-1 text-sm cursor-pointer bg-accent transition-colors text-white hover:bg-accent-light'
                    onClick={deleteButton}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </td>
            )}
        </tr>
    )
}

export default Row