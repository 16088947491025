import { faArrowLeft, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../Shared/Form/Button';
import Form from '../../Shared/Form/Form';
import Input from '../../Shared/Form/Input';
import Title from '../../Shared/Title';
import SidePanelWrapper from './../SidePanelWrapper';
import DigipasLogoWidePNG from "../../../Assets/digipaslogowide.png";
import { useAppSelector } from "../../../Redux/store";
import Success from "../../Shared/Form/Success";
import Error from "../../Shared/Form/Error";
import BusyArea from "../../Shared/BusyArea";
import { useResetPasswordMutation } from "../../../Redux/Api/Auth";
import LanguageDropdown from '../../Shared/LanguageDropdown';
import { useTranslation } from "react-i18next";
import { min } from '../../../Utils/InValidation';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const logoutError = useAppSelector((s) => s.auth.logoutReason);

    const [error, setError] = useState<string | undefined>(logoutError);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [busy, setBusy] = useState(false);
    const [params] = useSearchParams();
    const [resetPassword] = useResetPasswordMutation();

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1].toLowerCase();
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];

        if (validLanguages.includes(currentLang)) {
            i18n.changeLanguage(currentLang).then(() => {
            });
        }
    }, [i18n]);

    const submit = async (data: { password: string; confirmPassword: string }) => {
        if (busy) return;

        setBusy(true);
        setSuccessMessage('');
        setError('');

        if (data.password !== data.confirmPassword) {
            setError(t("resetPassword:error") || "");
            setBusy(false);
            return;
        }

        try {
            await resetPassword([params.get("token")!, data.password]).unwrap();
            setSuccessMessage(t("resetPassword:success") || "");
            setTimeout(() => navigate(`/${i18n.language}/login`), 2000);
        } catch (e) {
            setError(t("resetPassword:error") || "");
        }
        setBusy(false);
    };

    return (
        <>
            <SidePanelWrapper>
                <img src={DigipasLogoWidePNG} className="w-[40rem] mb-16 px-5 mt-16" />
                <Success text={successMessage} />
                <Error text={error} />
                <Title text={t("resetPassword:resetPassword")} subtitle={t("resetPassword:resetPasswordPrefix") || ""} />
                <BusyArea busy={busy}>
                    <LanguageDropdown style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }} />
                    <Form submit={submit}>
                        <Input required invalidator={ min(8) } label={t("resetPassword:passwordNew")} type='password' id='password'/>
                        <Input required invalidator={ min(8) } label={t("resetPassword:passwordRepeat")} type='password' id='confirmPassword'/>
                        <div className='flex gap-4 flex-wrap'>
                            <Button title={t("resetPassword:confirm")} />
                            <Button
                                nosubmit
                                action={() => navigate(`/${i18n.language}/login`)}
                                title={t("resetPassword:cancel")}
                            />
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    );
};

export default ResetPasswordPage;
