import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { TableContext } from './Table'

const TablePagination = () => {

    const ctx = useContext(TableContext)

    const [ totalPages, setTotalPages ] = useState(0)

    useEffect(() => {
        setTotalPages(Math.ceil(ctx.total / ctx.itemsPerPage))
    }, [ ctx.total ])

    const prev = () => {
        if(ctx.page > 0) {
            ctx.setPage(ctx.page - 1)
        }
    }

    const next = () => {
        if(ctx.page < totalPages - 1) {
            ctx.setPage(ctx.page + 1)
        }
    }

    return (
        <div className='flex items-center justify-center my-5'>
            <div className='flex gap-2 justify-center items-center'>
                <div onClick={ prev } className={`${ ctx.page > 0 && ' cursor-pointer' } select-none flex items-center justify-center w-10 h-10`}>
                    { ctx.page > 0 && <FontAwesomeIcon icon={ faChevronsLeft } /> }
                </div>
                <span className='w-20 text-center'>{ Math.min(ctx.page + 1, totalPages) } / { totalPages }</span>
                <div onClick={ next } className={`${ ctx.page < totalPages - 1 && ' cursor-pointer' } select-none flex items-center justify-center w-10 h-10`}>
                    { ctx.page < totalPages - 1 && <FontAwesomeIcon icon={ faChevronsRight } /> }
                </div>
            </div>
        </div>
    )
}

export default TablePagination