import React from 'react'
import Loader from '../Shared/Loader'

const LoadingPage = () => {
    return (
        <div className='flex flex-grow items-center h-full justify-center'>
            <Loader/>
        </div>
    )
}

export default LoadingPage