import React, { ReactNode } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useTranslation } from "react-i18next";
import {setDataGridSelection} from "../../Redux/Slices/Datagrid";

export type DataGridToolbarAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type DataGridToolbarProps = {
    actions?: Array<DataGridToolbarAction>,
    style?: React.CSSProperties,
    select?: any
    selectAll?: any,
    onSelectAll?: () => void,
    data?: any
};

export default function DataGridToolbar({ actions, style, data, selectAll, onSelectAll, select}: DataGridToolbarProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            {actions && actions.length > 0 && (
                <>
                    <Button className='bg-white hover:bg-blue-500 text-black hover:text-white py-2 mt-2' disabled={select === 0} onClick={handleClick} size="small" variant="contained" sx={{ ml: 1, mr: 2 }}>
                        <KeyboardArrowDownOutlinedIcon sx={{mr: 1}} /> {t('datagrid:actions')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'hidden',
                                borderRadius: 0,
                                border: '1px solid #D7E3F2',
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 10,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        {actions.map(action =>
                            <MenuItem key={action.label} onClick={() => action.onClick()} className="px-5 py-0">
                                <ListItemText className="py-1 px-1 pr-8" style={{ borderBottom: '1px solid #dbdbdb' }}>{action.label}</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                    {selectAll && (
                        <Button
                            style={{borderRadius: 0, border: '1px solid #D7E3F2', fontFamily: 'Inter'}} 
                            className='mt-2 bg-white hover:bg-blue-500 text-black hover:text-white px-3'
                            sx={{ ml: 1, mr: 2 }}
                            onClick={onSelectAll}
                        >
                            {select >= data ? t('datagrid:deselectAll') : t('datagrid:selectAll')}
                        </Button>
                    )}
                </>
            )}
        </GridToolbarContainer>
    );
}  