import * as React from "react";
import {LoginStatus} from "../../Redux/State/Auth";
import {Navigate} from "react-router-dom";
import { useAppSelector } from "../../Redux/store";

export interface PublicRouteProps {
    children: React.ReactElement | Array<React.ReactElement>
}

const PublicRoute = ({ children }: PublicRouteProps) => {
    const loginStatus = useAppSelector(state => state.auth.status);

    if(loginStatus === LoginStatus.NOT_LOGGED_IN) {
        return <>{ children }</>
    } else {
        return <>{ children }</>
    }

}

export default PublicRoute;