export namespace JWT {
    export function read<T>(key: string): T | undefined {
        let token = localStorage.getItem('token')
        if(!token) return

        let data = token.split('.')[1]
    
        let buf = JSON.parse(atob(data))

        return buf[key]
    }

    export function all(): any {
        let token = localStorage.getItem('token')
        if(!token) return

        let data = token.split('.')[1]
    
        let buf = JSON.parse(atob(data))

        return buf
    }
}