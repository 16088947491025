import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '../Shared/Form/Button'
import Title from '../Shared/Title'
import { useTranslation } from 'react-i18next';

const Page404 = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const handleNavigateBack = () => {
        const currentPath = location.pathname
        navigate(-1)
        setTimeout(() => {
            if (location.pathname === currentPath) {
                navigate("/login")
            }
        }, 100)
    }

    return (
        <>
            <div className='flex w-full h-full items-center justify-center'>
                <div className='flex flex-col p-20'>
                    <Title text="404" subtitle={t("404") || " "} />

                    <Button action={handleNavigateBack} icon={faArrowLeft} title={t("back")} />
                </div>
            </div>
        </>
    )
}

export default Page404
