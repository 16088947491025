import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useLazyGetUsersQuery } from '../../../../Redux/Api/Organisations';
import { UserResponse } from '../../../../Redux/Api/Request/User';
import { useDeleteUserMutation } from '../../../../Redux/Api/Users';
import { useAppSelector } from '../../../../Redux/store'
import { Strings } from '../../../../Strings/nl';
import { Hydra } from '../../../../Utils/Hydra';
import BusyArea from '../../../Shared/BusyArea';
import Button from '../../../Shared/Form/Button';
import Error from '../../../Shared/Form/Error';
import Success from '../../../Shared/Form/Success';
import Column from '../../../Shared/Table/Column';
import Data from '../../../Shared/Table/Data';
import Header from '../../../Shared/Table/Header';
import Row from '../../../Shared/Table/Row';
import Table from '../../../Shared/Table/Table';
import Title from '../../../Shared/Title';
import LoadingPage from '../../LoadingPage';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

const UsersPage = () => {
    const [error, setError] = useState('');
    const [busy, setBusy] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [deleteUser] = useDeleteUserMutation();
    const navigate = useNavigate();
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const [getUsers, { data: users }] = useLazyGetUsersQuery();
    const { t, i18n } = useTranslation();

    const isMediumScreen = useMediaQuery('(min-width: 768px)');
    const isSmallScreen = useMediaQuery('(min-width: 390px)');

    useEffect(() => {
        if (org_id) {
            getUsers(org_id);
        }
    }, [org_id, getUsers]);

    const deleteUserAction = async (id: string) => {
        if (busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            await deleteUser(id);
            await getUsers(org_id);
            setSuccessMessage(t('user:list:success') || '');
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }

        setBusy(false);
    };

    if (!users) return <LoadingPage />;

    return (
        <>
            <Title text={t('user:list:title')} textNotBold />
            <Error text={error} />
            <Success text={successMessage} />
            <BusyArea busy={busy}>
                <Table
                    subheader={
                        <div className="mb-2 flex gap-4">
                            <Button
                                action={() => navigate(`/${i18n.language}/dashboard/users/create`)}
                                className="my-6"
                                title={t('user:list:add')}
                                icon={faPlus}
                                iconright
                            />
                        </div>
                    }
                    withSearch
                    withEdit
                    withDelete
                >
                    <Header>
                        <Column name={t('user:list:name')} />
                        <Column name={t('user:list:email')} />
                        <Column hidden={!isMediumScreen} name={t('user:list:role')} />
                        <Column hidden={!isSmallScreen} name={t('user:list:status')} />
                    </Header>
                    <Data>
                        {/*@ts-ignore*/}
                        {users['hydra:member'].map((user: UserResponse, i: number) => (
                            <Row
                                key={ user.id }
                                index={ i }
                                editUrl={ `/${i18n.language}/dashboard/users/edit/` + user.id }
                                deleteAction={() => deleteUserAction(user.id)}
                                deleteConfirmMessage={t('user:list:delete') as string}
                                data={
                                    !isSmallScreen
                                        ? [
                                            `${user.firstname} ${user.lastname}`,
                                            user.email,
                                        ]
                                        : !isMediumScreen
                                            ? [
                                                `${user.firstname} ${user.lastname}`,
                                                user.email,
                                                Strings.nl?.roles?.[user.role as keyof typeof Strings.nl.roles] || 'Unknown Role',
                                            ]
                                            : [
                                                `${user.firstname} ${user.lastname}`,
                                                user.email,
                                                Strings.nl?.roles?.[user.role as keyof typeof Strings.nl.roles] || 'Unknown Role',
                                                user.active ? t('user:list:active') : t('user:list:inactive'),
                                            ]
                                }
                            />
                        ))}
                    </Data>
                </Table>
            </BusyArea>
        </>
    );
};

export default UsersPage;
