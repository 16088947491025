import React from 'react'
import SevereError from './SevereError'
import {useTranslation} from "react-i18next";

export interface ErrorProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text?: string
}
const Error = ({ text }: ErrorProps) => {
    const { t } = useTranslation()
    return (
        <SevereError subtitle={t('error') as string} error={ text } />
    )
}

export default Error