export function all(...validators: Array<(str: string) => false | string>) {
    return (str: string) => {
        for(let func of validators) {
            let res = func(str)
            if(res) return res
        }
        
        return false
    }
}

export function min(number: number) {
    return (str: string) => number > str.length && `Dit veld moet ${ number } karakters of langer zijn`
}

export function max(number: number) {
    return (str: string) => number < str.length && `Dit veld moet kleiner zijn dan ${ number } karakters`
}

export function isInt() {
    return (str: string) => parseInt(str).toString() != str && `Dit veld is geen geldig getal`
}

export function isPositive() {
    return (str:string) => parseFloat(str) <= 0 && `Dit veld moet meer dan 0 zijn`
}