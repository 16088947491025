import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetOrganisationsQuery } from '../../../../Redux/Api/Organisations';
import { useCreateUserMutation } from '../../../../Redux/Api/Users';
import { Strings } from '../../../../Strings/nl';
import { min } from '../../../../Utils/InValidation'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Dropdown from '../../../Shared/Form/Dropdown'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Error from '../../../Shared/Form/Error'
import { Hydra } from '../../../../Utils/Hydra'
import { useAppSelector } from '../../../../Redux/store'
import { JWT } from '../../../../Utils/JWT'
import { useTranslation } from "react-i18next";

const UsersCreatePage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!
    const org_id_ifadmin = localStorage.getItem('active_organisation')!
    const hidden = { hidden: 'false' };

    const { data: orgs } = useGetOrganisationsQuery(hidden, {
        refetchOnMountOrArgChange: true
    });
    const { t, i18n } = useTranslation()

    const navigate = useNavigate()

    const [createUser] = useCreateUserMutation()

    const [busy, setBusy] = useState(false)
    const [error, setError] = useState('')

    const isAdmin = JWT.read<Array<string>>('roles')?.includes('ROLE_SUPER_ADMIN');

    const roles = JWT.read<Array<string>>('roles') || []

    const submit = async (data: {
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        organisationId: string,
        role: string
    }) => {
        if (FailedRequirements(data, 'firstName', 'lastName', 'password', 'role', 'organisationId')) {
            return setError(t('modal:missing') as string);
        }

        if (busy) return;

        setBusy(true);
        setError('');

        if (!isAdmin) {
            data.organisationId = org_id;
        }

        try {
            await createUser(data as any).unwrap();
            navigate(`/${i18n.language}/dashboard/users/list`);
        } catch (e) {
            let err = e as Hydra.Error & { status?: number };

            if (err.status === 409) {
                setError(t('user:list:emailExists') as string);
            } else {
                setError(err['hydra:description'] || t('user:list:genericError') as string);
            }
            setBusy(false);
        }

        setTimeout(() => {
            setBusy(false);
        }, 200);
    };


    if (!orgs) return <LoadingPage />

    return (
        <>
            <Title text={t('user:list:userAdd')} textNotBold />
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <Error text={error} />
                    <Input
                        required
                        label={t('user:list:email')}
                        id='email' />
                    <Input
                        required
                        label={t('user:list:firstname')}
                        id='firstName' />
                    <Input
                        required
                        label={t('user:list:lastname')}
                        id='lastName' />
                    <Input
                        required
                        type='password'
                        invalidator={min(8)}
                        label={t('user:list:password')}
                        id='password' />

                    <input type='hidden' value={(org_id && !roles.includes('ROLE_SUPER_ADMIN')) ? (org_id) : (org_id_ifadmin)} name='organisationId' />

                    {!roles.includes('ROLE_USER') ? (
                        <Dropdown id='role' label={t('user:list:role')} initial='ROLE_USER' options={{
                            'ROLE_ADMIN': t('user:list:adminRole') as string,
                            
                            'ROLE_USER': t('user:list:userRole') as string
                        }} />
                    ) : (
                        <input type='hidden' value='ROLE_USER' name='role' />
                    )}
                    <Button title={t('user:list:add')} />
                </Form>
            </BusyArea>
        </>
    )
}

export default UsersCreatePage
