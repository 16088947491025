import { useState } from "react";
import { useTranslation } from "react-i18next";
import Field from "./Field";
import { useMapperContext } from "../Context/MapperContext";

const FieldsColumn = () => {
  const { t } = useTranslation();
  const [showDrop, setShowDrop] = useState(false);
  const {onDrop, fields} = useMapperContext();

  return (
    <section className="my-2.5 -ml-5 w-[346.3px]">
      <h2 className="flex items-center my-3.5 text-[18px] font-semibold">
        {t('mapping:stillToMap')}
      </h2>
      <div
        className="border-dashed border border-[#C0CAD8] h-[93%]"
        onDragEnter={() => setShowDrop(true)}
        onDragLeave={() => setShowDrop(false)}
        onDrop={(e) => {
          e.preventDefault();
          // @ts-ignore
          onDrop();
          setShowDrop(false);
          sessionStorage.removeItem("activeField");
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setShowDrop(true);
        }}
      >
          <div className="z-10 p-3">
            {fields
              .filter((field) => field.mappedTo === "")
              .map((field) => (
                <Field key={field.title} field={field} className="w-[320px]" />
              ))}
          </div>
          <section
            className={`w-full text-[#BBBBBB] transition-all duration-300 ease-in-out ${
              showDrop ? "mt-0 ml-5 opacity-100 h-[60px]" : "-mt-[30px] opacity-0 h-[30px]"
            }`}
          >
            {t("mapping:dropPrompt")}
          </section>
        </div>
    </section>
  );
};

export default FieldsColumn;
