import Title from "../../../Shared/Title";
import Error from "../../../Shared/Form/Error";
import Success from "../../../Shared/Form/Success";
import React, { useState, useEffect } from "react";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import { usePostSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { useAddSponsorLogoMutation } from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import { all, max, min } from "../../../../Utils/InValidation";
import Input, { FailedRequirements } from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import { useAppSelector } from "../../../../Redux/store";
import { Hydra } from "../../../../Utils/Hydra";
import { SponsorResponse } from "../../../../Redux/Api/Request/Sponsors";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLazyGetSponsorDataQuery } from "../../../../Redux/Api/Organisations";
import { useDeleteSponsorMutation } from "../../../../Redux/Api/Sponsors";

const SponsorEditPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [busy, setBusy] = useState(false);
    const [getSponsorData, { data }] = useLazyGetSponsorDataQuery();
    const [fileBlob, setFileBlob] = useState('');
    const { id } = useParams();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [uploadFile] = useUploadMutation();
    const [postSponsor] = usePostSponsorMutation();
    const [addSponsorLogo] = useAddSponsorLogoMutation();
    const [deleteSponsor] = useDeleteSponsorMutation();

    useEffect(() => {
        if (org_id) {
            getSponsorData({
                id: org_id
            });
        }
    }, [org_id, getSponsorData]);

    const sponsor = data?.find((sponsor) => sponsor.id === id);
    const sponsorIcon = sponsor?.icon;
    const sponsorID = sponsor?.id;

    const toMYSQLDate = (datestring: string) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const submit = async (data: {
        hiddenName: string,
        name: string,
        logo: File,
        activeFrom: string,
        activeUntil: string,

    }) => {
        if (data.activeUntil === null || data.activeUntil === '') {
            data.activeUntil = '9999-01-01';
        }
        if (FailedRequirements(data, 'name', 'logo', 'activeFrom', 'activeUntil')) return setError(t("sponsor:error") || "")
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            let upload_id = ''
            if (data.logo.name || data.logo.name.trim() !== '') {
                upload_id = await uploadFile({
                    file: data.logo
                }).unwrap();
            }

            const outData = {
                hiddenName: data.hiddenName,
                name: data.name,
                icon: sponsorIcon,
                from: toMYSQLDate(data.activeFrom),
                until: toMYSQLDate(data.activeUntil),
                organisation: org_id
            }

            const sponsor_id = await postSponsor([org_id, outData]).unwrap();

            if (data.logo.name || data.logo.name.trim() !== '') {
                const logo_url = await addSponsorLogo({
                    sponsor_id: sponsor_id.toString(),
                    upload_id
                });
            }

            await deleteSponsor(sponsorID as string);

            setSuccessMessage(t('sponsor:sponsorAdded') as string);
            setBusy(false)

            navigate(`/${i18n.language}/dashboard/sponsor/list`)
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
            setBusy(false);
        }
    }

    return (
        <>
            {sponsor ? (
                <>
                    <Title text={t('sponsor:titleEdit')} subtitle={t('sponsor:subtitleEdit') as string} />
                    <Error text={error} />
                    <Success text={successMessage} />

                    <BusyArea busy={busy}>
                        <Form submit={submit}>
                            <Input
                                invalidator={ all(min(0), max(255)) }
                                sublabel={t('cards:list:optional') as string}
                                initial={sponsor.hiddenName}
                                placeholder=""
                                label={t('sponsor:hiddenName')}
                                id='hiddenName' />

                            <Input
                                invalidator={ all(min(0), max(255)) }
                                sublabel={t('cards:list:optional') as string}
                                initial={sponsor.name}
                                placeholder=""
                                label={t('sponsor:name')}
                                id='name'/>

                            <FilePicker required id='logo' label={t('sponsor:logo')} onChangeBlob={setFileBlob} accept={['image/*']} initial={sponsor.icon} />

                            <div className="group w-fit">
                                <p className="-mt-8 mb-8 cursor-pointer hover:underline w-fit">{t('sponsor:showLogo')}</p>
                                {(fileBlob || sponsor.icon) && (
                                    <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none flex justify-center items-center z-[1000]">
                                        <img src={fileBlob || sponsor.icon} alt="Sponsor Logo Preview" className="max-h-[calc(100dvh-100px)] max-w-[calc(100dvw-100px)]" />
                                    </div>
                                )}
                            </div>

                            <CalendarInput
                                required
                                initial={sponsor.from}
                                label={t('sponsor:activeFrom')}
                                id='activeFrom' />
                            <CalendarInput
                                sublabel={t('cards:list:optional') as string}
                                label={t('sponsor:activeUntil')}
                                initial={sponsor.until}
                                id='activeUntil' />
                            <div className='flex mt-5'>
                                <Button title={t('sponsor:save')} />
                            </div>
                        </Form>
                    </BusyArea>
                </>
            ) : (null)}
        </>
    );
}

export default SponsorEditPage;
