import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import Button from '../../Shared/Form/Button';
import Form from '../../Shared/Form/Form';
import Input from '../../Shared/Form/Input';
import Title from '../../Shared/Title';
import SidePanelWrapper from '../SidePanelWrapper';
import { useLoginMutation } from "../../../Redux/Api/Auth";
import Error from '../../Shared/Form/Error';
import { Strings } from '../../../Strings/nl';
import { useAppDispatch, useAppSelector } from '../../../Redux/store';
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { JWT } from "../../../Utils/JWT";
import { logout } from "../../../Redux/State/Auth";
import BusyArea from "../../Shared/BusyArea";
import LanguageDropdown from '../../Shared/LanguageDropdown';

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const logoutError = useAppSelector((s) => s.auth.logoutReason);
    const [login, { isLoading }] = useLoginMutation();
    const [error, setError] = useState('');
    const [roleError, setRoleError] = useState('');
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1].toLowerCase();
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];

        if (validLanguages.includes(currentLang)) {
            i18n.changeLanguage(currentLang).then(() => {
            });
        }
    }, [i18n]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate(`/${i18n.language}/dashboard`);
        }
    }, [navigate, i18n.language]);

    const handleLogin = async ({ email, password }: { email: string, password: string }) => {
        if (busy) return;
        setBusy(true);
        setRoleError('');
        try {
            await login({ email, password }).unwrap();

            const roles = JWT.read<Array<string>>('roles') || [];
            if (roles.includes('ROLE_USER')) {
                dispatch(logout());
                setError(t('login:accesDenied') || "");
            } else {
                navigate(`/${i18n.language}/dashboard`);
            }
        } catch (e: any) {
            let error = e.status as keyof typeof Strings.nl.login_error;
            if (!Object.keys(Strings.nl.login_error).includes(error + '')) error = 'any';
            const errorKey = (Strings.nl.login_error[error]);
            setError(t(`login:${errorKey}`) as string);
        }

        setBusy(false);
    }

    return (
        <>
            <SidePanelWrapper style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', backgroundColor: 'white' }}>
                <BusyArea busy={busy}>
                    <LanguageDropdown style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }} />
                    <Title className="text-center" style={{ fontSize: '40px', fontFamily: 'Inter, sans-serif', fontWeight: '400', lineHeight: '1.5' }} text={t('login:welcome')} />
                    <Error text={error} />
                    <Error text={roleError} />
                    <Form submit={handleLogin} className='my-5'>
                        <Input label={t('login:email') as string} id='email' placeholder={t('login:email') as string} />
                        <Input type='password' label={t('login:password')} id='password' placeholder={t('login:password') as string} />
                        <div className="flex w-full mb-4">
                            <Button title={t('login:login')} icon={isLoading ? faSpinner : undefined} className="flex-1 py-2" style={{ backgroundColor: '#FF0058' }} />
                        </div>
                        <div className="flex mt-10">
                            <Link to={`/${i18n.language}/forgotpassword`} style={{ fontSize: '16px' }} className="w-full text-center no-underline hover:underline">
                                {t('login:forgotPassword')}
                            </Link>
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    )
}

export default LoginPage;
