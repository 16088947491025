import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';

export interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title?: any;
    free?: boolean;
    outline?: boolean;
    icon?: IconDefinition;
    rounded?: boolean;
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {

    const { title, free, outline, children, icon, rounded,...restProps } = props;

    return (
        <div
         ref={ ref }
         {...restProps} 
         className={`${restProps.className} flex flex-col relative ${ outline ? 'border-[1px] border-light-250' : 'bg-light-100' } p-3 `}
        style={{
            border: '1px solid #D7E3F2',
            borderRadius: rounded ? '2px' : '0',
        }}
        >
            {title && (
                <div  className='text-lg flex justify-between px-3 pt-2' style={{fontSize: '16px', color: '#5B6C79'}}>{title}{icon && <FontAwesomeIcon className='mt-1.5' icon={icon} />}</div>
            )}
            {children}
        </div>
    );
});

export default Card;
