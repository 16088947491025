import React from 'react'

export interface FormProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    submit: (data: any) => void,
    nostyle?: boolean
}

const Form = (props: FormProps) => {

    const { submit, className, nostyle, ...restProps } = props

    const preSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        let preData = new FormData(event.currentTarget)

        let formatedData: Record<string, string | undefined> = Object.fromEntries(preData.entries() as any)

        for(let k of Object.keys(formatedData)) {
            if(k.startsWith('@VALID@')) {
                delete formatedData[k]
                continue
            }

            if(formatedData['@VALID@' + k] == 'false') formatedData[k] = undefined
        }

        submit(formatedData)
    }

    return (
        <form {...restProps} className={ nostyle ? className : (className + ' mb-8')  } onSubmit={ preSubmit } />
    )
}

export default Form
