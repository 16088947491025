import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {AddLogoRequest, AddLogoResponse, AddSponsorLogoRequest} from "./Request/AddLogo";
import {SponsorResponse, SponsorResponseItem} from "./Request/Sponsors";

const sponsorApi = Api.injectEndpoints({
    endpoints: build => ({
        postSponsor: build.mutation< SponsorResponseItem, [string, Partial<SponsorResponseItem>]>({
            query: ([id, sponsor]) => ({
                url: '/api/sponsors',
                method: 'POST',
                body: JSON.stringify({...sponsor, organisation: id})
            })
        }),

        addSponsorLogo: build.mutation<AddLogoResponse, AddSponsorLogoRequest>({
            query: ({ sponsor_id, upload_id }) => ({
                url: `/api/sponsors/${sponsor_id}/add-sponsorlogo`,
                method: 'POST',
                body: JSON.stringify({ file: upload_id })
            })
        }),

        deleteSponsor: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/sponsors/${ id }`,
                method: 'DELETE'
            })
        })
    }),
});

export const {
    usePostSponsorMutation,
    useAddSponsorLogoMutation,
    useDeleteSponsorMutation,
} = sponsorApi;
export default sponsorApi;