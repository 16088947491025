import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import React, {useEffect, useState, useRef} from 'react';
import { createPortal } from 'react-dom'
import { Strings } from '../../../Strings/nl'
import {useTranslation} from "react-i18next";

export interface DropdownProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label: string,
    sublabel?: string,
    initial?: string,
    required?: boolean,
    options: Record<string, string | number>,
    onChange?: (value: string) => void,
    action?: () => void,
    onOpen?: () => void,
    value?: string,
    id?: string,
    up?: boolean
}


const Dropdown = (props: DropdownProps) => {
    const ref = useRef(null)
    const { label, sublabel, className, onOpen, required, up, onChange: onChangeOut, action, initial, options, id, value: selectedValue, ...restProps } = props
    const { t } = useTranslation()
    const labelOrId = id || label

    const [value, setValue] = useState(initial || selectedValue || '');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(!options) return;
        if(!initial) return;

        if(value !== initial) {
            setValue(initial);
        }
    }, [initial, selectedValue, options])

    useEffect(() => {
        setFilteredOptions(
            Object.fromEntries(
                Object.entries(options).filter(([k, v]) =>
                    String(v).toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
        );
    }, [searchTerm]);

    useEffect(() => {
        const checkIfClickedOutside = (e: { target: any })=> {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            // @ts-ignore
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    })

    const onChange = (item: string) => {
        setIsOpen(false)
        setValue(item)
        if(onChangeOut) onChangeOut(item)
    }

    const doChangeOpen = (value: boolean) => {
        if(value && onOpen) onOpen()

        setIsOpen(value)
    }


    return (

        <div {...restProps} ref={ref} className={`flex flex-col z-40 mb-6 mt-3 ${ className }`} >
            <label className='font-bold px-3' htmlFor={ labelOrId }>{ label }{ required && <span className='text-red-400'>*</span>} { sublabel && <span className='opacity-70 ext-sm'>({ sublabel })</span> }</label>
            <div className='flex flex-grow mt-1 relative top-2.5'  >
                <div onClick={() => doChangeOpen(!isOpen)} className='flex items-center cursor-pointer border-2 border-black border-opacity-10 flex-grow px-2 pl-3 py-2'>
                
                    {options[value] || '\u00a0'}
                    <FontAwesomeIcon className='text-sm ml-auto mr-4' icon={faChevronDown} />
                </div>
                <motion.div
                    layout
                    className={`absolute ${ up ? 'bottom-0' : 'mt-10' } overflow-hidden border-2 ${ value.length <= 0 && !isOpen ? 'border-red-400' : 'border-black border-opacity-10' } w-full ${ isOpen ? 'max-h-52 overflow-y-scroll opacity-100' : 'max-h-0 opacity-0 transition-opacity duration-1000' } flex flex-col bg-light-200 z-40`}>
                    <input
                        type="text"
                        placeholder={t('sidebar:search') || ''}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border-b border-black border-opacity-10 p-2 focus:outline-none"
                    />
                    {Object.entries(filteredOptions).map(([k, v], i) => (
                        <div key={ i } onClick={ () => onChange(k)} className={`flex flex-grow first:pt-4 last:pb-4 p-1 px-4 cursor-pointer hover:bg-blue-500 transition-colors hover:text-white ${k === value ? 'bg-blue-200' : ''}`}>{v}</div>
                    )) }
                </motion.div>
            </div>
            <input value={ value } type='hidden' name={ labelOrId } id={ labelOrId } />
            <input name={ '@VALID@' + labelOrId } type="hidden" value={ (value.length > 0) + '' } />
            { <motion.div initial={{ height: '0rem' }} animate={ (value.length > 0 || !required) ? { height: '0rem' } : { height: '1.2rem' } } className='text-sm overflow-hidden text-red-400 mb-2'>{ (value.length <= 0 && t('required')) || '\u00a0' }</motion.div> }
        </div>
    )
}

export default Dropdown