import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { logout } from '../State/Auth'
import { Strings } from '../../Strings/nl'
import {JWT} from "../../Utils/JWT";

const roles = JWT.read<Array<string>>('roles') || []

export const rtkQueryUnauthorizedHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if(action.payload.status === 401) {
            api.dispatch(logout(Strings.nl.error[401]))
        }

    }


    return next(action)
}