import React, { useState } from "react";
import drag from "../../../../../Assets/drag.svg";
import info from "../../../../../Assets/info.svg";
import { FieldProps } from "../types";
import { useTranslation } from "react-i18next";
import { useMapperContext } from "../Context/MapperContext";

const Field: React.FC<FieldProps> = ({ field, className }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { t } = useTranslation();
  const { setActiveField } = useMapperContext();

  return (
    <div
      className={`${className} flex flex-row items-center h-[45px] justify-between p-[15px] mb-[15px] cursor-grab bg-white border ${isDragging ? "border-2 border-[#3273F6]" : "border-[#414141]"}`}
      draggable
      onDragStart={() => {
        setActiveField(field.title);
        setIsDragging(true);
        sessionStorage.setItem("activeField", JSON.stringify(field.title));
      }}
      onDragEnd={() => {
        setActiveField(null);
        setIsDragging(false);
        sessionStorage.removeItem("activeField");
      }}
    >
      <img src={drag} alt="drag icon" />
      <div className="flex w-[75%] flex-row justify-center">
        <p className="text-[16px] truncate">{field.title}</p>
      </div>

      <div
        className="relative flex justify-center items-center"
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
      >
        {showPopup && (
          <div className="absolute bottom-full whitespace-nowrap mb-2 min-w-[200px] bg-white border border-gray-300 shadow-lg z-10">
            <ul className="list-disc list-inside">
              <p className="bg-map-blue p-2 text-white">{t("mapping:fileSample")}</p>
              {field.sampleItems && field.sampleItems.length > 0 ? (
                field.sampleItems.map((item, key) => (
                  <li key={key} className="px-2 flex">{item}</li>
                ))
              ) : (
                <li className="px-2 flex text-gray-500">{t("mapping:noItemsAvailable")}</li>
              )}
            </ul>
          </div>
        )}
        <img src={info} alt="detail" className="cursor-pointer" />
      </div>
    </div>
  );
};

export default Field;
