import { useTranslation } from "react-i18next";
import { useGetSuggestionsQuery } from '../../../Redux/Api/Suggestion';
import React, { useState, useEffect } from 'react';

interface SuggestionsProps {
    isAwaitingResponse: boolean;
    onSuggestionClick: (suggestion: string) => void;
}

const ChatboxSuggestions: React.FC<SuggestionsProps> = ({ isAwaitingResponse, onSuggestionClick }) => {
    const { t, i18n } = useTranslation();
    const { data: Suggestion } = useGetSuggestionsQuery();
    type SuggestionResponseItem = {
        id: string;
        title: string;
        text: { [key: string]: string };
    };

    const [suggestions, setSuggestions] = useState<SuggestionResponseItem[]>([]);

    useEffect(() => {
        if (Suggestion) {
            const currentPageUrl = window.location.pathname;
            const filteredSuggestions = Suggestion.filter((note) => currentPageUrl === '/' + i18n.language + (note.title));
            if (filteredSuggestions.length > 2) {
                setSuggestions(filteredSuggestions.sort(() => 0.5 - Math.random()).slice(0, 2));
            } else {
                setSuggestions(filteredSuggestions);
            }
        }
    }, [Suggestion, i18n.language, window.location.pathname]);

    if (!Suggestion) return null;
    const textKey = `text_${i18n.language}`;

    const handleSuggestionClick = (suggestion: string, noteId: string) => {
        onSuggestionClick(suggestion);
        setSuggestions((prevSuggestions) => prevSuggestions.filter((note) => note.id !== noteId));
    };

    return (
        <>
            {suggestions.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                    {suggestions.map((note) => (
                        <button
                            key={note.id}
                            onClick={() => handleSuggestionClick((note.text as { [key: string]: string })[textKey], note.id)}
                            className="disabled:opacity-50 disabled:cursor-default bg-[#E6F0FF] text-[#3273f6] text-[11px] rounded-full px-3 py-1 text-xs"
                            disabled={isAwaitingResponse}
                        >
                            {(note.text as { [key: string]: string })[textKey]}
                        </button>
                    ))}
                </div>
            ) : (
                <div className="text-[#3273f6] text-[11px] text-center mt-2">
                    {t("patrick:noSuggestions")}
                </div>
            )}
        </>
    );
};

export default ChatboxSuggestions;
