import { HTMLMotionProps, motion } from 'framer-motion'
import React, { useContext } from 'react'
import { TableContext } from './Table'

export interface HeaderProps extends HTMLMotionProps<"thead"> {
}

const Header = (props: HeaderProps) => {

    const ctx = useContext(TableContext)

    const { children, ...restProps } = props

    return (
        <motion.thead {...restProps} className=''>
            <tr className='font-bold'>
                { ctx.withSelectionHeader && <td/> }
                { children }
            </tr>
        </motion.thead>
    )
}

export default Header