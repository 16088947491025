import Api from "./Api";
import { TokenRequest, TokenResponse } from "./Request/Token";
import {ForgotPasswordRequest} from "./Request/Password";

const authApi = Api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<TokenResponse, TokenRequest>({
            query: (credentials) => ({
                url: '/token',
                method: 'POST',
                body: credentials,
            })
        }),

        forgotPassword: build.mutation<string, ForgotPasswordRequest>({
            query: (email) => ({
                url: '/api/public/users/forgot-password',
                method: 'POST',
                body: JSON.stringify(email),
            })
        }),

        resetPassword: build.mutation<string, [string, string]>({
            query: ([token, password]) => ({
                url: '/api/public/users/reset-password',
                method: 'POST',
                body: JSON.stringify({token: token, password: password}),
            })
        })
    }),
});

export const { useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApi;
export default authApi;