import React, {PropsWithChildren} from "react";
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";

type ThemeProperties = PropsWithChildren<{}>;

const theme = createTheme({
    palette: {
        primary: {
            main: '#0175FF',
        },
        secondary: {
            main: '#f2f2f2',
        },
    },
});

export default function Theme({children}: ThemeProperties) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
