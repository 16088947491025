export namespace Strings {
    export const nl = {
        roles: {
            'ROLE_USER': 'Gebruiker voor scanners',
            'ROLE_ADMIN': 'Beheerder',
        },
        link: {
            'download_android': 'https://play.google.com/store/apps/details?id=com.xinteractive.android.digipas',
            'download_ios': 'https://apps.apple.com/nl/app/digipas.app/id1633608697'
        },
        message: {
            busy: 'busy',
        },
        form: {
            required: 'fieldIsRequired',
        },
        error: {
            401: 'noRightsToThisAction',
        },
        login_error: {
            'any': 'networkErrorTryAgainLater',
            401: 'emailOrPasswordIncorrect',
            403: 'noAcces'
        }
    }
}