import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './Components/App'
import './default.css'
import 'moment/dist/locale/nl'
import Theme from './Components/Layout/Theme'
import i18next from 'i18next'
import './Services/i18n/i18n';
import * as Sentry from "@sentry/react";

moment.locale('nl')

Sentry.init({
    dsn: "https://e9792cc8b42ae4cd92b5a2e6af58072f@sentry.x-interactive.nl/30",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Theme>
            <App/>
        </Theme>
    </React.StrictMode>
)
