import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'

export interface DropdownInputProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label?: string,
    sublabel?: string,
    initial?: string,
    options: Array<string>,
    onChange?: (value: string) => void,
    onOpen?: () => void,
    id?: string,
    up?: boolean,
    value?: string,
    placeholder?: string,
    rounded?: boolean
}

const DropdownInput = (props: DropdownInputProps) => {
    const { label, rounded, sublabel, className, onOpen, up, onChange: onChangeOut, initial, options, id, value: propValue, placeholder, ...restProps } = props

    const labelOrId = id || label

    const [value, setValue] = useState(initial || '')
    const [isOpen, setIsOpen] = useState(false)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        if (propValue !== undefined) {
            setValue(propValue)
            setSearchText(propValue)
        }
    }, [propValue])

    const onChange = (item: string) => {
        setIsOpen(false)
        setValue(item)
        if (onChangeOut) onChangeOut(item)
    }

    const onChangeValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value
        setValue(value)
        setSearchText(value)
        if (onChangeOut) onChangeOut(value)
    }

    const doChangeOpen = (value: boolean) => {
        if (value && onOpen) onOpen()
        setIsOpen(value)
    }

    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(searchText.toLowerCase())
    )

    const sortedOptions = filteredOptions.sort((a, b) => {
        const isDigitA = /^\d/.test(a)
        const isDigitB = /^\d/.test(b)

        if (isDigitA && !isDigitB) return 1
        if (!isDigitA && isDigitB) return -1
        return a.localeCompare(b, 'en', { numeric: true })
    })

    return (
        <div {...restProps} className={`flex flex-col  ${className}`}>
            <label htmlFor={labelOrId}>
                {label} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}
            </label>
            <div className='flex flex-grow relative cursor-pointer'>
                <div className={`flex items-center border border-black bg-light-100  border-opacity-30 flex-grow gap-2 p-1 ${isOpen && ' z-[800]'}`} style={{ borderRadius: rounded ? '3px' : '0' }}>
                    <input
                        onFocus={() => doChangeOpen(true)}
                        className='h-[30px] flex flex-grow bg-transparent focus:outline-none cursor-pointer px-2'
                        value={value}
                        onChange={(evt) => onChangeValue(evt)}
                        type='text'
                        autoComplete='off'
                        name={labelOrId}
                        id={labelOrId}
                        style={{ width: '100%' }}
                        placeholder={placeholder}
                    />
                    <FontAwesomeIcon onClick={() => doChangeOpen(!isOpen)} className='text-sm ml-auto mr-2 text-gray-500' icon={faCaretDown} />
                </div>
                <motion.div
                    layout
                    className={`absolute ${up ? 'bottom-0' : 'mt-[45px]'} overflow-hidden border-2 w-full ${isOpen ? 'opacity-100 z-[800] max-h-52 overflow-y-scroll' : 'max-h-0 opacity-0 transition-opacity duration-50'} flex flex-col bg-light-200 z-40`}>

                    {sortedOptions.length === 0 && (
                        <div key="empty" className='flex flex-grow first:pt-2 last:pb-2 p-1 px-4 cursor-pointer hover:bg-blue-500 transition-colors hover:text-white'>
                            -
                        </div>
                    )}
                    {sortedOptions.map((k, i) => (
                        <div key={i} onClick={() => onChange(k)} className='flex flex-grow first:pt-2 last:pb-2 p-1 px-4 cursor-pointer hover:bg-blue-500 transition-colors hover:text-white'>{k}</div>
                    ))}
                </motion.div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        key="background-div"
                        onClick={() => setIsOpen(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.15 }}
                        exit={{ opacity: 0 }}
                        className='fixed left-0 top-0 w-screen h-screen bg-black z-40'
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default DropdownInput
