import React from 'react'
import Title from '../Title'
import {useTranslation} from "react-i18next";

export interface SuccessAddProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text?: string
}

const SuccessAdd = ({ text }: SuccessAddProps) => {
    const { t } = useTranslation()
    if(!text) return <></>
    return (
        <Title className='text-white bg-green-500 p-10' subtitle={ text } text={t('success')}/>
    )
}

export default SuccessAdd