import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { CardResponseItem } from "../../../Redux/Api/Request/Cards";
import CheckIcon from "@mui/icons-material/Check";
import { GridCloseIcon } from "@mui/x-data-grid";

type CheckedInRendererProps = {
  card: CardResponseItem;
  familyCards?: boolean;
  small?: boolean;
};

export default function CheckedInRenderer(props: CheckedInRendererProps) {
  const card = props.card;
  const classes = useStyles();
  const { t } = useTranslation();

  if (props.familyCards) {
    if (card.currentlyCheckedInNumber) {
      return (
        <div className={classes.checkedIn}>
          {!props.small && <CheckIcon fontSize="small" className="pb-1 mr-1" />}
          {`${card.currentlyCheckedInNumber} / ${card.numberOfPeople}`}
        </div>
      );
    } else {
      return (
        <div className={classes.checkedOut}>
          {!props.small && <GridCloseIcon fontSize="small" className="pb-1 mr-1" />}
          {`0 / ${card.numberOfPeople}`}
        </div>
      );
    }
  } else {
    if (card.isCheckedIn) {
      return (
        <div className={classes.checkedIn}>
          {!props.small && <CheckIcon fontSize="small" className="pb-1" />}
          {t("cards:list:yes")}
        </div>
      );
    } else {
      return (
        <div className={classes.checkedOut}>
          {!props.small && <GridCloseIcon fontSize="small" className="pb-1" />}
          {t("cards:list:no")}
        </div>
      );
    }
  }
}

const useStyles = makeStyles((theme) => ({
  checkedIn: {
    border: "solid 1px #D7E3F2",
    color: "black",
    padding: "1px 7px 1px 7px",
    borderRadius: 20,
    lineHeight: 2,
  },
  checkedOut: {
    border: "solid 1px #D7E3F2",
    color: "black",
    padding: "1px 7px 1px 7px",
    borderRadius: 20,
    lineHeight: 2,
  },
}));