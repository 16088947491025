import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Title from '../Title';
import { useTranslation } from 'react-i18next';

export interface SuccessProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text?: string;
}

const Success = ({ text }: SuccessProps) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(!!text);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (text) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [text]);

    useEffect(() => {
        if (!isVisible) return;

        let timer: NodeJS.Timeout;

        if (!isHovered) {
            timer = setTimeout(() => {
                setIsVisible(false);
            }, 10000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isHovered, isVisible]);

    if (!text) return <></>;

    return (
        <motion.div
            className="overflow-hidden"
            initial={{ height: 'auto' }}
            animate={{ height: isVisible ? 'auto' : 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsVisible(false)}
        >
            <Title
                className="text-white bg-green-500 p-10 cursor-pointer"
                subtitle={text}
                text={t('success')}
            />
        </motion.div>
    );
};

export default Success;
