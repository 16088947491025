import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    useGetBlocksQuery,
    useGetCardQuery,
    usePostActivationLinkEmailMutation,
    usePostBlockMutation,
    usePostUnblockMutation,
    usePutCardMutation,
} from '../../../../Redux/Api/Cards';
import {useGetTypeQuery, usePostTypeMutation} from '../../../../Redux/Api/Types';
import { all, isInt, isPositive, max, min } from '../../../../Utils/InValidation'
import {CardEditItem, CardResponseItem} from '../../../../Redux/Api/Request/Cards'
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import CalendarInput from '../../../Shared/Form/CalendarInput'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Pill from '../../../Shared/Form/Pill'
import DropdownInput from '../../../Shared/Form/DropdownInput'
import { Hydra } from '../../../../Utils/Hydra'
import Constants from '../../../../Constants'
import Success from '../../../Shared/Form/Success'
import Page404 from '../../Page404'
import Switch from "../../../Shared/Form/Switch";
import {useLazyGetTypeDataQuery} from "../../../../Redux/Api/Organisations";
import {useTranslation} from "react-i18next";
import FilePicker from "../../../Shared/Form/FilePicker";
import {useGetNewsItemQuery, usePutNewsMutation, useAddNewsLogoMutation} from "../../../../Redux/Api/News";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'

const EditNewsPage = () => {
    const modalContext = useContext(ModalContext)
    const navigate = useNavigate()
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const [ text, setText ] = useState('')
    const [ fileBlob, setFileBlob ] = useState('');
    const [ error, setError ] = useState('')
    const [ busy, setBusy ] = useState(false)
    const [ successMessage, setSuccessMessage ] = useState('')

    const [ putNews ] = usePutNewsMutation();
    const [addNewsLogo] = useAddNewsLogoMutation();
    const [uploadFile] = useUploadMutation();

    const handleText = (e: any) => {
        setText(e.target.value)
    }

    if(!id) return <Page404/>

    const org_id = useAppSelector((s) => s.organisation.id)!

    const { data } = useGetNewsItemQuery({
        id: id!
    })

    const newsImage = data?.image
    const newsID = data?.id

    const submit = async(news: {
        title: string,
        image: File,
        url: string
    }) => {
        if(FailedRequirements(news, 'title')) return setError(t('modal:error') as string)
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        if (text.length > 49000){
            setError(t('organisation:news:tooLong') as string);
            setBusy(false);
            return;
        }

        const outData = {
            title: news.title,
            text: text,
            url: news.url
        }

        try{
            let upload_id = ''
            if (news.image.name || news.image.name.trim() !== '') {
                upload_id = await uploadFile({
                    file: news.image
                }).unwrap();
            }

            await putNews({
                id,
                ...outData
            }).unwrap()

            if (news.image.name || news.image.name.trim() !== '') {
                await addNewsLogo({
                    news_id: id.toString(),
                    upload_id
                });
            }

            setSuccessMessage(t('organisation:news:successEdit') as string)
            setTimeout(() => {
                window.location.replace(`/${i18n.language}/dashboard/organisation/news`);
            }, 1000);
        }catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 300)
    }

    useEffect(() => {
        if(!data) return
        setText(data.text)
    }, [ data ])

    if(!data) return <LoadingPage/>

    return (
        <>
            <Title text={t('organisation:news:title')} subtitle={t('organisation:news:editNews') as string} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />

            <BusyArea busy={ busy }>
                <Form submit={ submit }>
                    <Input
                        required
                        invalidator={ all(min(2), max(255)) }
                        label={t('organisation:news:tableTitle')}
                        initial={ data.title }
                        id='title'/>
                    <FilePicker required id='image' label={t('organisation:news:image')} onChangeBlob={ setFileBlob } accept={['image/*']} initial={data.image} />
                    <div className="group w-fit">
                                <p className="-mt-8 mb-8 cursor-pointer hover:underline w-fit">{t('organisation:news:showImage')}</p>
                                {(fileBlob || data.image) && (
                                    <div className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none flex justify-center items-center z-[1000]">
                                        <img src={fileBlob || data.image} alt="News Image Preview" className="max-h-[calc(100dvh-100px)] max-w-[calc(100dvw-100px)]" />
                                    </div>
                                )}
                            </div>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        invalidator={ all(min(0), max(255)) }
                        label={t('organisation:news:url')}
                        initial={ data.url }
                        placeholder="https://"
                        type='url'
                        id='url'/>
                    <label>{t('organisation:news:text')} <span className='opacity-70 text-sm'>({t('cards:list:optional')})</span></label>
                    <ReactQuill
                        value={ text }
                        onChange={ setText }
                        id='text'
                        className={'flex flex-col mb-14 bg-transparent p-1 border-black border-opacity-10 w-full h-80'}
                    />
                    <div className='flex'>
                        <Button title={t('organisation:news:save')} />
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default EditNewsPage
