export namespace Hydra {
    export interface Result<T> {
        '@context': string,
        '@id': string,
        '@type': string
        'hydra:member': Array<T>
        'hydra:totalItems': number,
    }

    export interface Error {
        '@context': string,
        '@id': string,
        '@type': string,
        'hydra:description': string
    }

    export function MultiResponseTransformer(value: Result<any>) {
        return value["hydra:member"]
    }

    export function SingleResponseTransformer(value: Result<any>) {
        return value as any
    }
}