import {useState} from "react";
import validator from 'validator';

export namespace CSVLoader {
    export function load(text: string, t: any): Promise<{ error?: string, data?: Array<Array<string>> }> {

        return new Promise((res) => {

            const lines = text.split('\n')
            // Fixes wrong date format when exported from english version of Excel
            const fixDate = (date: string) => {
                if (!date) return '';

                let match = date.match(/^(?<d>\d{1,2})[\/.](?<m>\d{1,2})[\/.](?<y>\d{4})$/);
                if (match) {
                    let { d, m, y } = match.groups as Record<string, string>;
                    d = d.padStart(2, '0');
                    m = m.padStart(2, '0');
                    y = y.padStart(4, '20');
                    return `${d}-${m}-${y}`;
                }

                match = date.match(/^(?<d>\d{1,2})-(?<m>\d{1,2})-(?<y>\d{4})$/);
                if (match) {
                    let { d, m, y } = match.groups as Record<string, string>;
                    d = d.padStart(2, '0');
                    m = m.padStart(2, '0');
                    y = y.padStart(4, '20');
                    return `${d}-${m}-${y}`;
                }

                match = date.match(/^(?<d>\d{1,2})[\/.](?<m>\d{1,2})-(?<y>\d{4})$/);
                if (match) {
                    let { d, m, y } = match.groups as Record<string, string>;
                    d = d.padStart(2, '0');
                    m = m.padStart(2, '0');
                    y = y.padStart(4, '20');
                    return `${d}-${m}-${y}`;
                }

                return date
            };

            const checkDate = (items: any, number:number) => {
                let arrayOfErrors: string[] = [];
                let i = 2;
                items.forEach((item: any) => {
                    let match1 = item[number].match(/^(?<d>\d{1,2})[\/.](?<m>\d{1,2})[\/.](?<y>\d{4})$/);     
                    let match2 = item[number].match(/^(?<d>\d{1,2})-(?<m>\d{1,2})-(?<y>\d{4})$/);     
                    let match3 = item[number].match(/^(?<d>\d{1,2})[\/.](?<m>\d{1,2})-(?<y>\d{4})$/);
                    if (match1) {}
                    else if (match2) {} 
                    else if (match3) {} 
                    else{arrayOfErrors.push(' '+ (i - 1))} {}
                    i++;
                })

                if(arrayOfErrors.length <= 0) {
                    return false
                } else {
                    return res({
                        error: t('cards:list:importError', { line: arrayOfErrors.toString(), column: number.toString()}) ?? 'error',
                        data: rows
                    })
                }
            };    

            const checkEmail = (email: string) => {
                if (validator.isEmail(email))
                {
                    return true
                } else {
                    return false
                }
            }

            const mailHasErrors = (items: any) => {
                let arrayOfErrors: string[] = [];
                let i = 2;
                items.forEach((item: any) => {
                    if (item[3] && !checkEmail(item[3])) {
                        arrayOfErrors.push(' '+ (i - 1))
                    }
                    i++;
                })
                if(arrayOfErrors.length <= 0) {
                    return false
                } else {
                    return res({
                        error: 'Het emailadres op lijn ' + arrayOfErrors.toString() + ' klopt niet of bevat een spatie. Pas dit aan in het CSV bestand en upload opnieuw!',
                        data: rows
                    })
                }
            }

            const rowHasErrors = (items: any, field: string, number: number) => {
                let arrayOfErrors: string[] = [];
                let i = 2;

                items.map((item: any) => {
                    if (!item[number] || item[number].length === 0) {
                        arrayOfErrors.push(' '+ (i - 1))
                    }
                    i++;
                })
                if(arrayOfErrors.length <= 0) {
                    return false
                } else {
                    return res({error: field +' op lijn '+ arrayOfErrors.toString() +' zijn leeg of niet correct ingevuld. Pas dit aan in het CSV bestand en upload opnieuw!', data: rows});
                }
            }

            const rows = lines
                .map((line) => line.split(/;|,/))
                .slice(1)
                .filter((row) => row.length > 0)
                let annNumber = 1;
                rows.forEach((item) => {
                    item.unshift(annNumber.toString());
                    annNumber++;
                });

            rows.find((items) => {
                if (items[6].trim().length <= 0) {
                    items[6] = '01-01-9999';
                }
            })

                if (rowHasErrors(rows, 'Initialen/voornamen', 1)) { return rowHasErrors(rows, 'Initialen/voornamen', 1) }
                if (rowHasErrors(rows, 'Achternamen', 2)) { return rowHasErrors(rows, 'Achternamen', 2) }
                if (mailHasErrors(rows)) { return mailHasErrors(rows); }
                if (rowHasErrors(rows, 'Lidnummers', 4)) { return rowHasErrors(rows, 'Lidnummers', 4) }
                if (rowHasErrors(rows, 'Geldig van velden', 5)) { return rowHasErrors(rows, 'Geldig van velden', 5) }
                if (checkDate(rows, 5)) { return checkDate(rows, 5); } else { 
                    rows.map((list) => {
                    list[5] = fixDate(list[5]);
                    return list;
                    })
                }
                if (checkDate(rows, 6)) { return checkDate(rows, 6); } else { 
                    rows.map((list) => {
                    list[6] = fixDate(list[6]);
                    return list;
                    });
                }

            res({ data: rows })
        })

    }
}
