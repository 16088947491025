import FieldsColumn from "../MapperComponents/FieldsColumn";
import MappedColumn from "../MapperComponents/MappedColumn";
import SystemColumn from "../MapperComponents/SystemColumn";

function Mapper() {

    return (
      <div className="flex flex-row gap-6">
        <SystemColumn />
        <MappedColumn />
        <FieldsColumn />
      </div>
    );
  }

export default Mapper