import React, { createContext, ReactElement, useEffect, useState } from 'react'
import { min } from '../../../Utils/InValidation'
import Button from '../Form/Button'
import Dropdown from '../Form/Dropdown'
import Input from '../Form/Input'
import TablePagination from './TablePagination'
import TableSelectionHeader from './TableSelectionHeader'
import {useTranslation} from "react-i18next";

export const ITEMS_PER_PAGE = 25

export interface TableProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableElement>, HTMLTableElement> {
    withSearch?: boolean,
    withDelete?: boolean,
    withEdit?: boolean,
    withSelectionHeader?: boolean,
    onAction?: (list: any, action: string) => void,
    itemsPerPage?: number,
    actions?: Record<string, string>,
    subheader?: ReactElement
    className?: string
}

export const TableContext = createContext<{
    setColumns: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    columns: Record<string, string>,
    setColumn: (name: string, type: string) => void,
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    sort: [string, string],
    selection: Record<any, any>,
    setSelection: React.Dispatch<React.SetStateAction<Record<any, any>>>,
    setSort: React.Dispatch<React.SetStateAction<[string, string]>>,
    page: number,
    setPage: React.Dispatch<React.SetStateAction<number>>,
    total: number,
    setTotal: React.Dispatch<React.SetStateAction<number>>,
    withDelete?: boolean,
    withEdit?: boolean,
    withSelectionHeader?: boolean,
    itemsPerPage: number,
    setSelectAll: React.Dispatch<React.SetStateAction<() => void>>
}>({
    columns: {},
    setColumns: () => void 0,
    setColumn: () => void 0,
    selection: {},
    setSelection: () => void 0,
    search: '',
    setSearch: () => void 0,
    sort: ['','down'],
    setSort: () => void 0,
    page: 0,
    setPage: () => void 0,
    total: 0,
    setTotal: () => void 0,
    itemsPerPage: ITEMS_PER_PAGE,
    setSelectAll: () => void 0
})

const Table = (props: TableProps) => {

    const [ rand, setRand ] = useState(Math.random())
    const { t } = useTranslation()

    const [ columns, setColumns ] = useState<Record<string, string>>({})
    const [ search, setSearch ] = useState<string>('')
    const [ selection, setSelection ] = useState<Record<number, any>>([])
    const [ sort, setSort ] = useState<[string, string]>(['', 'down'])
    const [ page, setPage ] = useState<number>(0)
    const [ total, setTotal ] = useState<number>(0)
    const [ selectAll, setSelectAll ] = useState<() => void>(() => void 0)

    const setColumn = (name: string, type: string) => {
        setColumns((cols) => {
            cols[name] = type

            return cols
        })
    }

    const { withSearch, subheader, withEdit, itemsPerPage, withSelectionHeader, actions, onAction, withDelete, className, ...restProps } = props

    // This is to force the first re-render
    useEffect(() => {
        setRand(Math.random())
    }, [ ])


    return (
        <div className={className}>
        <TableContext.Provider value={{
            columns,
            setColumns,
            search,
            setSearch,
            setColumn,
            sort,
            setSort,
            withEdit,
            withDelete,
            selection,
            setSelection,
            withSelectionHeader,
            page,
            setPage,
            total,
            setTotal,
            itemsPerPage: itemsPerPage || ITEMS_PER_PAGE,
            setSelectAll
        }}>
            <>
                { withSearch && <Input valueChange={ setSearch } label={t('search')} /> }
                { withSelectionHeader && <TableSelectionHeader selectAll={ selectAll } actions={ actions || {} } onAction={ onAction || (() => void 0) } /> }
                { subheader }
                <div className='flex flex-col flex-grow relative overflow-x-auto'>
                    <table {...restProps} className='w-full' />
                    { total <= 0 && (
                        <div className='h-20 w-full flex justify-center items-center'>
                            <div className='text-center text-gray-500'>{t('noResult')}</div>
                        </div>
                    )}
                </div>
                <TablePagination />
            </>
        </TableContext.Provider>
        </div>
    )
}

export default Table