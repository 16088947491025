import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AppState {
    sidebaropen: boolean
}

const initialState: AppState = {
    sidebaropen: false
}

const App = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSideBarOpen(state, action: PayloadAction<boolean>) {
            state.sidebaropen = action.payload
        }
    }
})

export const { setSideBarOpen } = App.actions

export default App.reducer