import { IconDefinition, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    title: string,
    action?: (arg?: any) => void;
    nosubmit?: boolean
    icon?: IconDefinition,
    iconright?: boolean,
    secondary?: boolean,
    disabled?: boolean,
    notRelative?: boolean
}

const Button = (props: ButtonProps) => {

    const { title, action, className, secondary, disabled, nosubmit, iconright, icon, notRelative, ...restProps } = props

    const spinning = icon === faSpinner;

    return (
        <button type={ nosubmit ? 'button' : 'submit' } {...restProps}
            className={`${ !notRelative && 'relative'} ${ disabled && 'opacity-40' } snow-button flex cursor-pointer transition-colors min-w-[6rem] px-6 py-2 ${ secondary ? 'hover:bg-light-100 border-2 text-gray-700 border-accent-light' : 'text-white bg-accent hover:bg-accent-light' } justify-center gap-4 items-center ${ className }`} onClick={ props.action }>
            { icon && !iconright && <FontAwesomeIcon className={`w-3 h-3 ${spinning ? 'animate-spin' : ''}`} icon={ icon } /> }
            { title }
            { icon && iconright && <FontAwesomeIcon className={`w-3 h-3 ${spinning ? 'animate-spin' : ''}`} icon={ icon } /> }

        </button>
    )
}

export default Button
