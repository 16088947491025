import { createContext, useContext} from 'react';
import {MapperContextProps} from '../types';

export const MapperContext = createContext<MapperContextProps | undefined>(undefined);

export const useMapperContext = (): MapperContextProps => {
  const context = useContext(MapperContext);
  if (!context) {
    throw new Error('useMapperContext must be used within a MapperProvider');
  }
  return context;
};
