import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import authApi from '../Api/Auth';

export enum LoginStatus {
    LOGGED_IN,
    NOT_LOGGED_IN
}

export interface AuthState {
    token: string | null
    status: LoginStatus,
    logoutReason?: string
}

const initialState: AuthState = {
    status: localStorage.getItem('token') ? LoginStatus.LOGGED_IN : LoginStatus.NOT_LOGGED_IN,
    token: localStorage.getItem('token') || null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state: Draft<AuthState>, { payload } : PayloadAction<string | undefined>) => {
            state.token = null
            state.status = LoginStatus.NOT_LOGGED_IN
            localStorage.removeItem('token')

            if(payload) {
                state.logoutReason = payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state: Draft<AuthState>, { payload }) => {
                state.token = payload.token
                state.status = LoginStatus.LOGGED_IN
                state.logoutReason = undefined
                localStorage.setItem('token', payload.token)
            }
        )
    }
})

export const { logout } = authSlice.actions

export default authSlice.reducer