import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Constants from '../../Constants'
import { Hydra } from '../../Utils/Hydra'
import type { RootState } from "../store"
import { AddLogoRequest } from './Request/AddLogo'
import { BlockRequest, BlockResponse } from './Request/Block'
import { CardRequest, CardResponseItem, CardsResponse } from './Request/Cards'
import { MassActionRequest, MassActionResponse } from './Request/MassAction'
import { NewsResponse } from './Request/News'
import { NewsFeedRequest } from './Request/NewsFeed'
import { NotificationRequest } from './Request/Notification'
import { OrganisationPostRequest, OrganisationPutRequest, OrganisationRequest, OrganisationResponse, OrganisationResponseItem } from './Request/Organisations'
import { ScanResponse } from './Request/Scan'
import { SocialsRequest } from './Request/Socials'
import { ThemeRequest } from './Request/Theme'
import { Type, TypesResponse } from './Request/Types'
import { UploadRequest, UploadResponse, MultipleFileUploadRequest } from './Request/Upload'
import { RegiserUserRequest, UserResponse, UsersResponse } from './Request/User'

const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: Constants.API_URL,
        prepareHeaders: (headers, { getState }) => {
            const state = (getState() as RootState)
            const token = state.auth.token
            
            if (state.auth.token) {
                headers.set('Authorization', `Bearer ${ token }`)
            }

            if(!headers.has('Content-Type'))
                headers.set('Content-Type', 'application/json')

            if(headers.get('Content-Type') == 'auto')
                headers.delete('Content-Type')

            return headers
        }
    }),
    endpoints: build => ({
        upload: build.mutation<UploadResponse, UploadRequest>({
            query: ({ file }) => {
                const data = new FormData()
                const headers = new Headers()

                data.append('file', file)

                headers.set('Content-Type', 'auto')
                
                return {
                    url: '/api/upload',
                    method: 'POST',
                    body: data,
                    headers: headers,
                    responseHandler: 'text'
                }
            },
        }),
        
        uploadMultiple: build.mutation<UploadResponse, FormData>({
            query: (data) => {
               const headers = new Headers()
               headers.set('Content-Type', 'auto')
                return {
                    url: '/api/upload/multiple',                    
                    method: 'POST',
                    body: data,
                    headers: headers,
                    responseHandler: 'json'
                };
                
            },
        }),
        

        getImage: build.query<File, string>({
            query: (id) => ({
                url: `/api/image/${id}`,
                method: 'GET',
            })
        })
        
    })
})
export const {
    useUploadMutation,
    useGetImageQuery,
    useUploadMultipleMutation,
} = baseApi
export default baseApi