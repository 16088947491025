import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { createPortal } from 'react-dom'
import { Strings } from '../../Strings/nl'
import Loader from './Loader'
import {useTranslation} from "react-i18next";

export interface BusyAreaProps {
    busy?: boolean,
    children?: React.ReactNode
}


const BusyArea = ({ busy, children }: BusyAreaProps) => {
    const { t } = useTranslation()
    return (
        <>
            {   createPortal(
                (
                    <div
                        style={{
                            pointerEvents: busy ? 'auto' : 'none',
                            background: busy ? 'rgb(0, 0, 0, 0.5)' : 'transparent'
                        }}
                        className='fixed flex items-center transition-colors justify-center overflow-hidden inset-0 z-[100000]'>
                            <AnimatePresence>
                                { busy && <motion.div
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.2 }}
                                className='absolute flex flex-col items-center justify-center inset-0 z-40'>
                                    <Loader/>
                                    <div className='text-white text-3xl'>{ t('busy') }</div>
                                </motion.div> }
                            </AnimatePresence>
                    </div>
                ), document.getElementById('busy')!)
            }
            { children }
        </>
    )
}

export default BusyArea
