import { ReactNode, useContext, useState } from "react";
import { MapperContext } from "./MapperContext";
import { CsvData, Field, FoundError, SystemField } from "../types";
import { useTranslation } from "react-i18next";
import Papa from 'papaparse';
import Constants from "../../../../../Constants";
import { useAppSelector } from "../../../../../Redux/store";
import { useNavigate } from "react-router-dom";
import * as fs from 'fs';
import { saveAs } from 'file-saver';
import { useFetchCsvDataMutation, useCheckIsUploadedFileMutation, useHandleDisplayErrorMutation, useHandleExtractFieldsMutation, useScanForErrorsMutation, useUploadCsvFileMutation, useDownloadProcessedFileMutation } from "../../../../../Redux/Api/PatrickAiApi";

interface MapperProviderProps {
  children: ReactNode;
}

export const MapperProvider: React.FC<MapperProviderProps> = ({ children }) => {
  const [csvData, setCsvData] = useState<CsvData>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [errorsFound, setErrorsFound] = useState<FoundError[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [t, i18n] = useTranslation();
  const [activeField, setActiveField] = useState<string | null>(null);
  const [isFileError, setIsFileError] = useState<boolean>(false);
  const [isExtractingFields, setIsExtractingFields] = useState<boolean>(false);
  const [fileErrorCheck, setFileErrorCheck] = useState<boolean>(false);
  const [extractFieldError, setExtractFieldsError] = useState<string | null>(null);
  const [failedError, setFailedError] = useState<string | null>(null);
  const [showData, setShowData] = useState<boolean>(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [blobFile, setBlobFile] = useState<Blob | null>(null);
  const org_id = useAppSelector((s) => s.organisation.id)!;
  const navigate = useNavigate();
  const [isUploadedFile, setIsUploadedFile] = useState<boolean>(false)
  const [handleExtractFields] = useHandleExtractFieldsMutation();
  const [uploadCsvFile] = useUploadCsvFileMutation();
  const [handleDisplayError] = useHandleDisplayErrorMutation();
  const [scanForErrors] = useScanForErrorsMutation();
  const [checkIsUploadedFile] = useCheckIsUploadedFileMutation();
  const [fetchCsvData] = useFetchCsvDataMutation();
  const [downloadProcessedFile] = useDownloadProcessedFileMutation();

  const systemFields: SystemField[] = [
    { title: t("mapping:initials/firstname"), value: "Initialen/Voornaam", option: "Mandatory", checkbox: false },
    { title: t("mapping:middle/lastname"), value: "Tussenvoegsel+achternaam", option: "Mandatory", checkbox: false },
    { title: t("mapping:number"), value: "Nummer", option: "Mandatory", checkbox: false },
    { title: t("mapping:validFrom"), value: "Geldig van", option: "Mandatory", checkbox: true },
    { title: t("mapping:types"), value: "Type(s) (Erelid, Senior, Team etc. Meerdere types scheiden met |)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:email"), value: "E-mailadres(optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:validUntil"), value: "Geldig tot(optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:dateOfBirth"), value: "Geboortedatum (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:location"), value: "Locatie (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:phoneNumber"), value: "Telefoonnummer (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:department"), value: "Afdeling (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:numberOfPersons"), value: "Aantal personen (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:discountCode"), value: "kortingscode (optioneel)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:countryCode"), value: "Landcode(NL, DE, FR, EN, ES)", option: "Not Mandatory", checkbox: false },
    { title: t("mapping:team"), value: "Team (optioneel)", option: "Not Mandatory", checkbox: false },
  ];

  function mandatoryFieldsMapped() {
    const requiredFields: string[] = [];

    systemFields.map((item, index) => {
      if (index < 3) {
        requiredFields.push(item.value);
      } else if (index === 3 && !isChecked) {
        requiredFields.push(item.value);
      }
    });

    const mappedFields = fields.map(field => field.mappedTo);

    return requiredFields.every(required => mappedFields.includes(required));
  }

  const handleShowAlert = () => {
    setIsAlertModalOpen(true);
  };

  const handleCloseModal = () => {
    setAlertMessage("")
    setAlertTitle("")
    setIsAlertModalOpen(false);
  };

  const onDrop = (mappedTo: string | null, position: number) => {
    if (!activeField) return;

    const updatedFields = fields.map((field) =>
      field.title === activeField
        ? { ...field, mappedTo: mappedTo || "" }
        : field
    );

    const updatedField = updatedFields.find(field => field.title === activeField);
    if (!updatedField) return;

    const reorderedFields = [
      ...updatedFields.filter(field => field.title !== activeField),
      updatedField
    ];

    setFields(reorderedFields);
  };

  function parseCSV(csvString: string) {
    const parsedArray = Papa.parse(csvString, {
      delimiter: ",",
      skipEmptyLines: true
    }).data;
    return parsedArray as CsvData;
  }

  function handleBackToMap() {
    setIsFileError(false)
  };

  function prepareCsvUploadData(data: string[][]): Blob {
    const csvContent = data
      .map(row => row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(','))
      .join('\n');

      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      setBlobFile(csvBlob)
      return csvBlob;
  }

  const handleDownload = async () => {
    try {
      const fileBlob = await downloadProcessedFile(org_id).unwrap();

      if (fileBlob) {
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `processed-digipas.csv`; 
        link.click(); 
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  }

  const handleCheck = async () => {
    try {
      const data = await checkIsUploadedFile(org_id).unwrap();
      setIsUploadedFile(true);
    } catch (err) {
      navigate("/" + i18n.language + "/dashboard/cards/import");
    }
  };

  const handleFetch = async () => {
    try {
      const data = await fetchCsvData(org_id).unwrap();
      setCsvData(data.response)
    } catch (err) {
      console.error('Error fetching CSV data:', err);
    }
  };

  const handleScan = async () => {
    setFailedError(null)
    setErrorsFound([])
    try {
      setIsExtractingFields(true);
      setFileErrorCheck(true)
      const data = await scanForErrors(org_id).unwrap();
      setIsFileError(true);
      setErrorsFound(data.response.issues);
    } catch (error) {
      setFailedError("There was an error when checking file for errors"); //translate this
    } finally {
      setIsExtractingFields(false);
      setFileErrorCheck(false)
    }
  };

  const handleExtract = async () => {
    setIsExtractingFields(true);
    try {
      const data = await handleExtractFields(org_id).unwrap();
      setFields(data.response);
    } catch (error) {
        setExtractFieldsError(t("mapping:extractError"));
        console.error('Error:', error);
    } finally {
      setIsExtractingFields(false);
    }
  };

  const handleFileUpload = async () => {
    const file = prepareCsvUploadData(csvData);
    try {
      const response = await uploadCsvFile({ org_id, file }).unwrap();
      return true;
    } catch (error) {
      console.error('Error uploading file:', error);
      return false
    }
  };

  const handleErrorDisplay = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setFailedError(null)
    setErrorsFound([])

    if (!mandatoryFieldsMapped()) {
      setAlertMessage(t("mapping:errorMapMandatory") as string)
      setAlertTitle(t("mapping:errorMapMandatoryFix") as string)
      handleShowAlert();
      return;
    }

    try {
      setIsExtractingFields(true);
      setFileErrorCheck(true);
      if (!isUploadedFile) {
        return;
      }
      const mappedFields = fields.filter(item => item.mappedTo !== "").map(item => ({ title: item.title, mappedTo: item.mappedTo }));
      const data = await handleDisplayError({ org_id, mapped: mappedFields, is_checked: isChecked }).unwrap();
      setIsFileError(true);
      setErrorsFound(data.response.issues);
      handleFetch()
    } catch (err) {
      setFailedError("An error occurred when checking file for errors");
    } finally {
      setIsExtractingFields(false);
      setFileErrorCheck(false);
    }
  };
  

  return (
    <MapperContext.Provider value={{
      csvData,
      setCsvData,
      errorsFound,
      fields,
      setActiveField,
      systemFields,
      onDrop,
      isChecked,
      setIsChecked,
      isFileError,
      fileErrorCheck,
      setFileErrorCheck,
      setIsFileError,
      isExtractingFields,
      setIsExtractingFields,
      failedError,
      setFailedError,
      extractFieldError,
      setExtractFieldsError,
      showData,
      setShowData,
      isAlertModalOpen,
      setIsAlertModalOpen,
      alertTitle,
      setAlertTitle,
      alertMessage,
      setAlertMessage,
      handleScan,
      handleExtract,
      handleErrorDisplay,
      handleCheck,
      handleFetch,
      handleFileUpload,
      handleBackToMap,
      handleDownload,
      handleShowAlert,
      handleCloseModal,
    }}>
      {children}
    </MapperContext.Provider>
  );
};

export const useMapperContext = () => {
  const context = useContext(MapperContext);
  if (context === undefined) {
    throw new Error('useMapperContext must be used within a MapperProvider');
  }
  return context;
};

