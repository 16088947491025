import React from "react";
import { MapDropAreaProps } from "../types";
import { useTranslation } from "react-i18next";

const MapDropArea: React.FC<MapDropAreaProps> = ({ onDrop, dragging, className }) => {
  const cssDropArea = `h-[45px] text-[#BBBBBB] border-dashed border border-[#C0CAD8] p-[15px] mb-[15px] opacity-100 whitespace-nowrap ${className}`;
  const { t } = useTranslation();

  return (
    <section
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
      className={`${cssDropArea} ${dragging ? "" : ""}`}
    >
      {t ("mapping:dropPrompt")}
    </section>
  );
};

export default MapDropArea;
