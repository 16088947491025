import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretDown, faTrash, faFileExport, faFileImport } from '@fortawesome/pro-solid-svg-icons';
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface CardsFilterPresetProps {
    onPresetChanged: (filters: Filter[]) => void;
    parentFilters: Filter[];
}

type Filter = {
    field: string;
    value: string;
    displayname: string;
    displayvalue: string;
};

type Preset = {
    name: string;
    filters: Filter[];
};

const CardsFilterPreset: React.FC<CardsFilterPresetProps> = ({ onPresetChanged, parentFilters }) => {
    const { t } = useTranslation();
    const [presets, setPresets] = useState<Preset[]>([]);
    const [activeFilters, setActiveFilters] = useState<Filter[]>(parentFilters);
    const [newPresetName, setNewPresetName] = useState<string>("");
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string>("");
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const savedPresets = getPresets();
        setPresets(savedPresets);
        setActiveFilters(parentFilters);
    }, [parentFilters]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (feedbackMessage) {
            const timer = setTimeout(() => {
                setFeedbackMessage("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [feedbackMessage]);

    const getPresets = (): Preset[] => {
        const savedPresets = localStorage.getItem('presets');
        return savedPresets ? JSON.parse(savedPresets) : [];
    };

    const savePresets = (newPresets: Preset[]): void => {
        localStorage.setItem('presets', JSON.stringify(newPresets));
    };

    const handlePresetNameChange = (name: string): void => {
        setNewPresetName(name);
        if (name === "/export") {
            exportPresets();
            setNewPresetName("");
        } else if (name === "/import") {
            document.getElementById("importFileInput")?.click();
            setNewPresetName("");
        }
    };

    const addPreset = (): void => {
        if (presets.length >= 50) {
            setFeedbackMessage(t("cards:list:limitReached") as string);
            return;
        }

        if (!newPresetName) {
            setFeedbackMessage(t("cards:list:enterName") as string);
            return;
        }

        if (activeFilters.length === 0) {
            setFeedbackMessage(t("cards:list:addFilter") as string);
            return;
        }

        let uniqueName = newPresetName;
        let count = 1;
        while (presets.some(preset => preset.name === uniqueName)) {
            uniqueName = `${newPresetName} (${count++})`;
        }

        const newPreset: Preset = {
            name: uniqueName,
            filters: activeFilters,
        };
        const updatedPresets = [...presets, newPreset];
        setPresets(updatedPresets);
        savePresets(updatedPresets);
        setNewPresetName("");
        setFeedbackMessage(t("cards:list:presetAdded") as string);
    };

    const removePreset = (presetToRemove: Preset): void => {
        const updatedPresets = presets.filter(preset => preset.name !== presetToRemove.name);
        setPresets(updatedPresets);
        savePresets(updatedPresets);
        setFeedbackMessage(t("cards:list:presetRemoved", { presetName: presetToRemove.name }) as string);
    };

    const applyPreset = (preset: Preset): void => {
        setActiveFilters(preset.filters);
        onPresetChanged(preset.filters);
        setFeedbackMessage(t("cards:list:presetApplied", { presetName: preset.name }) as string);
    };

    const exportPresets = (): void => {
        if (presets.length === 0) {
            setFeedbackMessage(t("cards:list:noPresetsToExport") as string);
            return;
        }
        const presetData = JSON.stringify(presets, null, 2);
        const blob = new Blob([presetData], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = t("cards:list:exportFileName");
        link.click();
        setFeedbackMessage(t("cards:list:presetsExported") as string);
    };

    const importPresets = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const importedPresets = JSON.parse(e.target?.result as string);
                    const combinedPresets = [...presets];
                    importedPresets.forEach((importedPreset: Preset) => {
                        let uniqueName = importedPreset.name;
                        let count = 1;
                        while (combinedPresets.some(preset => preset.name === uniqueName)) {
                            uniqueName = `${importedPreset.name} (${count++})`;
                        }
                        combinedPresets.push({ ...importedPreset, name: uniqueName });
                    });
                    setPresets(combinedPresets);
                    savePresets(combinedPresets);
                    setFeedbackMessage(t("cards:list:presetsImported") as string);
                } catch (error) {
                    setFeedbackMessage(t("cards:list:importFailed") as string);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <>
            <style>{`
                .lower-opacity::placeholder {
                    opacity: 0.5;
                }
            `}</style>

            <div className="flex flex-col gap-4 px-3">
                <div className="flex flex-col xl:flex-row items-start xl:items-center justify-between gap-2 mt-4">
                    <div className="flex gap-2 w-full">
                        <input
                            type="text"
                            value={newPresetName}
                            onChange={(e) => handlePresetNameChange(e.target.value)}
                            className="px-3 py-2 w-full xl:w-auto border border-black/25 rounded-md hover:border-black outline-none lower-opacity"
                            maxLength={50}
                            placeholder={t("cards:list:enterPresetName") as string}
                        />

                        <button
                            className={`flex items-center justify-center bg-green-500 text-white px-4 py-2 rounded-md cursor-pointer whitespace-nowrap ${!newPresetName || activeFilters.length === 0 || presets.length >= 50 ? 'pointer-events-none opacity-50' : 'hover:bg-green-400'} transition ease-in-out duration-200`}
                            onClick={addPreset}
                            disabled={!newPresetName || activeFilters.length === 0}
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-0 sm:mr-2" />
                            <span className="hidden sm:block">{t("cards:list:createPreset")}</span>
                        </button>
                    </div>

                    <div className="relative w-full xl:w-auto" ref={dropdownRef}>
                        <div
                            className="flex items-center px-3 py-2 border border-black/25 rounded-md hover:border-black cursor-pointer whitespace-nowrap justify-between"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            <span className="opacity-25">{t("cards:list:selectPreset")}</span>
                            <FontAwesomeIcon icon={faCaretDown} className={`ml-2 ${isDropdownOpen ? 'rotate-180' : ''}`} />
                        </div>
                        {isDropdownOpen && (
                            <div className="absolute top-full left-0 mt-1 w-full bg-white border border-black/25 rounded-md z-10 overflow-hidden max-h-48 overflow-y-auto">
                                {presets.length > 0 ? (
                                    presets.map((preset, index) => (
                                        <div key={index} className="flex items-center justify-between px-3 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { applyPreset(preset); setIsDropdownOpen(false); }}>
                                            {preset.name}
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="ml-2 text-red-500 cursor-pointer hover:scale-110 transition ease-in-out duration-200"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removePreset(preset);
                                                }}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="px-3 py-2 text-gray-500">{t("cards:list:noPresets")}</div>
                                )}
                            </div>
                        )}
                    </div>

                    <input
                        id="importFileInput"
                        type="file"
                        accept=".txt"
                        onChange={importPresets}
                        className="hidden"
                    />

                </div>
            </div>
            {feedbackMessage && (
                <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} className="ml-1 mt-2 text-sm text-gray-700 absolute bottom-0 right-6">
                    {feedbackMessage}
                </motion.div>
            )}
        </>
    );
}

export default CardsFilterPreset;
