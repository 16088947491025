import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {useUploadMutation} from '../../../../Redux/Api/Api';
import {
    useAddLogoMutation,
    useGetOrganisationQuery, useLazyGetOrganisationSettingsQuery,
    usePutOrganisationMutation, usePutSettingsMutation,
} from '../../../../Redux/Api/Organisations';
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import ColorPicker from '../../../Shared/Form/ColorPicker'
import Dropdown from '../../../Shared/Form/Dropdown'
import Error from '../../../Shared/Form/Error'
import FilePicker from '../../../Shared/Form/FilePicker'
import Form from '../../../Shared/Form/Form'
import Input from '../../../Shared/Form/Input'
import ThemePreview from '../../../Shared/ThemePreview'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Page404 from '../../Page404'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {useAppSelector} from "../../../../Redux/store";
import IconSVG from '../../../Assets/icon.svg?component'
import nlflag from '../../../../Assets/nl.svg'
import enflag from '../../../../Assets/en.svg'
import deflag from '../../../../Assets/de.svg'
import esflag from '../../../../Assets/es.svg'
import frflag from '../../../../Assets/fr.svg'
import {InputLabel, MenuItem, Select, responsiveFontSizes} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import { Background } from 'victory';
import { Language } from '@mui/icons-material';
import {useTranslation} from "react-i18next";
import validator from 'validator';

const OrganisationEditPage = () => {
    const navigate = useNavigate()

    const { id: orgId } = useParams()

    if(!orgId) return <Page404/>

    const { data, isError } = useGetOrganisationQuery({ id: orgId! }, {
        refetchOnMountOrArgChange: true
    })

    const [ putOrganisation ] = usePutOrganisationMutation()
    const [ addLogo ] = useAddLogoMutation()
    const [ uploadFile ] = useUploadMutation()
    const [ getOrganisationsSettings, {data: settings} ] = useLazyGetOrganisationSettingsQuery()
    const [ putSettings ] = usePutSettingsMutation()

    const [ busy, setBusy ] = useState(false)
    const [ error, setError ] = useState('')

    const [ tint, setTint ] = useState('#E6171A')
    const [ lighterTint, setLighterTint ] = useState('#ed4245')
    const [ fileBlob, setFileBlob ] = useState('')

    const [ hidden, setHidden ] = useState( false);
    const [ notification, setNotification ] = useState( false);
    const [ sponsor, setSponsor ] = useState(false);
    const [ patrickAI, setPatrickAI ] = useState(false);
    const [ scan, setScan ] = useState(false);
    const [ photo, setPhoto ] = useState(false);
    const [ familyCards, setFamilyCards ] = useState(false);
    const [ maxNumberOfPeople, setMaxNumberOfPeople ] = useState(8);
    const [ organisationLanguage, setOrganisationLanguage ] = useState('');
    const [ organisationEmail, setOrganisationEmail ] = useState('');
    const { t, i18n } = useTranslation()

    useEffect(() => {
        if(!data || !data.settings) return

        setTint(data.settings.theme.colors.tint)
        setLighterTint(data.settings.theme.colors.lighterTint)

        setFileBlob(data.settings.theme.icon)
    }, [ data ])

    useEffect(() => {
        if(orgId)
            getOrganisationsSettings(orgId)

        if(settings) {
            setNotification(settings.notification)
            setSponsor(settings.sponsors)
            setPatrickAI(settings.patrickAI)
            setScan(settings.scan)
            setPhoto(settings.photo)
            setFamilyCards(settings.familyCards)
            setMaxNumberOfPeople(settings.maxNumberOfPeople || 8)
            setOrganisationLanguage(settings.organisationLanguage)
            setOrganisationEmail(settings.organisationEmail)
            setHidden(data?.settings?.hidden ?? false)
        }
    }, [ orgId, settings ])

    const isEmailValid = (email: string) => {
        return validator.isEmail(email);
    };

    const submit = async ({ name, type, logo, organisationLanguage, organisationEmail }: { name: string, type: string, logo: any, organisationLanguage: string, organisationEmail:string,}) => {
        if(busy) return

        setBusy(true)
        setError('')

        try {

            if (settings) {
                console.log(patrickAI)
                putSettings({
                    id: orgId,
                    notification: notification,
                    sponsor: sponsor,
                    patrickAI: patrickAI,
                    scan: scan,
                    photo: photo,
                    familyCards: familyCards,
                    maxNumberOfPeople: familyCards ? maxNumberOfPeople : 8

                }).unwrap()
            }
            if(isEmailValid(organisationEmail)){
            const outData = {
                name,

                type,

                settings: {
                    ...(data?.settings || {}),
                    organisationLanguage,
                        organisationEmail,
                        hidden,
                    theme: {
                        ...(data?.settings?.theme || {}),
                        colors: {
                            ...(data?.settings?.theme?.colors || {}),
                            tint: tint || data?.settings?.theme?.colors?.tint,
                            lighterTint: lighterTint || data?.settings?.theme?.colors?.lighterTint
                        },
                    },
                }
            }
            const { id } = await putOrganisation([ orgId!, outData ]).unwrap()

            if(logo) {
                const upload_id = await uploadFile({
                    file: logo
                }).unwrap()

                await addLogo({
                    organisation_id: id,
                    upload_id
                }).unwrap()
            }

            navigate(`/${i18n.language}/dashboard/admin/organisation/list`)
            } else{
                setError(t('organisation:theme:validEmail') || '');
            }
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const changeNotification = () => {
        setNotification(!notification)
    }

    const changeHidden = () => {
        setHidden(!hidden)
    }

    const changeSponsor = () => {
        setSponsor(!sponsor)
    }

    const changePatrickAI = () => {
        setPatrickAI(!patrickAI)
    }

    const changeScan = () => {
         setScan(!scan)
    }

    const changePhoto = () => {
        setPhoto(!photo)
    }

    const changeFamilyCards = () => {
        setFamilyCards(!familyCards)
    }

    const [language, setLanguage] = useState('NL');

    if(isError) return <Page404/>
    if(!data || !settings) return <LoadingPage/>

    const theme = createTheme({
        components: {
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        // Controls default (unchecked) color for the thumb
                        color: "#ccc"
                    },
                    colorPrimary: {
                        "&.Mui-checked": {
                            // Controls checked color for the thumb
                            color: data?.settings?.theme?.colors?.tint
                        }
                    },
                    track: {
                        // Controls default (unchecked) color for the track
                        opacity: 0.2,
                        backgroundColor: "#656565",
                        ".Mui-checked.Mui-checked + &": {
                            // Controls checked color for the track
                            opacity: 0.7,
                            backgroundColor: data?.settings?.theme?.colors?.lighterTint
                        }
                    }
                }
            }
        }
    });



    return (
        <>
            <Error text={ error } />
            <Title text={t('organisation:editOrganisation')} textNotBold/>
            <BusyArea busy={ busy }>
                <ThemeProvider theme={theme}>
                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changeNotification}
                                    checked={notification}
                                />
                            }
                            label={t('admin:notifications')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changeSponsor}
                                    checked={sponsor}
                                />
                            }
                            label={t('admin:sponsors')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changePatrickAI}
                                    checked={patrickAI}
                                />
                            }
                            label={t('patrick:name')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changeScan}
                                    checked={scan}
                                />
                            }
                            label={t('admin:checkInOut')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changePhoto}
                                    checked={photo}
                                />
                            }
                            label={t('admin:changePhoto')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={changeFamilyCards}
                                    checked={familyCards}
                                />
                            }
                            label={t('admin:familyPass')}
                        />
                        {familyCards && (
                            <Input
                                type="number"
                                label={t('admin:numberOfFamilyCards')}
                                value={maxNumberOfPeople.toString()}
                                onChange={(e) => setMaxNumberOfPeople(parseInt((e.target as HTMLInputElement).value))}
                                id="numberOfFamilyCards"
                            />
                        )}
                        <br />
                        <FormControlLabel
                                control={
                                    <Switch
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={changeHidden}
                                        checked={hidden}
                                    />
                                }
                                label={t('admin:hideInPortal')}
                        />
                        <br />
                    </FormGroup>
                </ThemeProvider>
                <Form submit={ submit }>
                    <Input
                        required
                        label={t('admin:name')}
                        initial={ data.name }
                        id='name'/>

                    <Input
                        required
                        label={t('admin:replyEmail')}
                        initial={data.settings?.organisationEmail || 'support@digipas.app'}
                        id='organisationEmail'/>


            <div className= 'flex flex-grow flex-col max-w-1' style={{paddingBottom:35}}>
                <label htmlFor="organizationLanguage">{t('admin:organizationLanguage')}</label>

                <Select
                defaultValue={data.settings?.organisationLanguage} onChange={(event) => setLanguage(event.target.value)}
                    id="organisationLanguage"
                    name="organisationLanguage"
                >

                   <MenuItem value="NL"><img src={nlflag} alt="Dutch" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="mr-3 " />{t('organisation:theme:NL')}</MenuItem>
                    <MenuItem value="EN"><img src={enflag} alt="English" id="EN" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ENG')}</MenuItem>
                    <MenuItem value="DE"><img src={deflag} alt="German" id="DE" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:DE')}</MenuItem>
                    <MenuItem value="FR"><img src={frflag} alt="France" id="FR" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:FR')}</MenuItem>
                    <MenuItem value="ES"><img src={esflag} alt="Spain" id="ES" style={{height: 20, width: 30, paddingTop: 1, paddingBottom: 1, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ES')} </MenuItem>

                </Select>
            </div>

                    <Dropdown
                        required
                        label={t('admin:type')}
                        initial={ data.type }
                        id='type'
                        options={{ soccer: 'Voetbal' }}
                        style={{marginBottom: '50px'}}
                    />
                    <div className='flex flex-wrap flex-grow gap-x-20 gap-y-10'>
                        <div className='flex flex-col flex-grow'>
                            <Title text={t('admin:theme')} />
                            <ColorPicker initial='#E6171A' color={ tint } onChangeReactive={ setTint } id='tint' label={t('admin:primaryColor')} />
                            <ColorPicker initial='#ed4245' color={ lighterTint } onChangeReactive={ setLighterTint } id='lighterTint' label={t('admin:secondaryColor')} />
                            <FilePicker id='logo' label='Logo' onChangeBlob={ setFileBlob } accept={['image/*']} />
                            <div className='flex'>
                                <Button title={t('admin:save')} />
                            </div>
                        </div>
                        <ThemePreview tint={ tint } lighterTint={ lighterTint } fileBlob={ fileBlob } />
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default OrganisationEditPage
