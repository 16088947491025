import { CardResponseItem } from "../../../Redux/Api/Request/Cards";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from "react-i18next";

type StatusRendererProps = {
    card: CardResponseItem
    small?: boolean
}

export default function StatusRenderer({ card, small = false }: StatusRendererProps) {
    const classes = useStyles(small);
    const { t } = useTranslation();

    const paddingClass = small ? classes.paddingSmall : classes.padding;

    if (card.block) {
        return (
            <div className={classes.error + ' ' + paddingClass}>
                <ErrorOutlineIcon fontSize='small' className='pb-1' />
                {!small && t('datagrid:block')}
            </div>
        );
    } else if (moment(card.activeFrom).format('YYYYMMDD') > moment().format('YYYYMMDD')) {
        return (
            <div className={classes.warning + ' ' + paddingClass}>
                <WarningAmberIcon fontSize='small' className='pb-1' />
                {!small && t('datagrid:inactive')}
            </div>
        );
    } else if (moment(card.activeUntil).format('YYYYMMDD') < moment().format('YYYYMMDD')) {
        return (
            <div className={classes.warning + ' ' + paddingClass}>
                <WarningAmberIcon fontSize='small' className='pb-1' />
                {!small && t('datagrid:inactive')}
            </div>
        );
    } else {
        return (
            <div className={classes.correct + ' ' + paddingClass}>
                <CheckIcon fontSize='small' className='pb-1' />
                {!small && t('datagrid:active')}
            </div>
        );
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    warning: {
        color: 'white',
        backgroundColor: '#fc9803',
        borderRadius: 20,
        lineHeight: 2,
    },

    correct: {
        color: 'white',
        backgroundColor: '#00C45E',
        borderRadius: 20,
        lineHeight: 2,
    },

    error: {
        color: 'white',
        backgroundColor: '#fc2c03',
        borderRadius: 20,
        lineHeight: 2,
    },

    padding: {
        padding: '2px 10px 2px 10px',
    },

    paddingSmall: {
        padding: '0px 6px 0px 6px',
    }
}));