import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Title from '../Title';

export interface SevereErrorProps {
    error?: string;
    subtitle?: string;
}

const SevereError = ({ error, subtitle }: SevereErrorProps) => {
    const [isVisible, setIsVisible] = useState(!!error);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (error) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [error]);

    useEffect(() => {
        if (!isVisible) return;

        let timer: NodeJS.Timeout;

        if (!isHovered) {
            timer = setTimeout(() => {
                setIsVisible(false);
            }, 10000);
        }

        return () => clearTimeout(timer);
    }, [isHovered, isVisible]);

    if (!error) return null;

    return (
        <motion.div
            className="overflow-hidden"
            initial={{ height: 'auto' }}
            animate={{ height: isVisible ? 'auto' : 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsVisible(false)}
        >
            <Title
                className="text-white bg-red-500 p-10 max-w-screen cursor-pointer"
                subtitle={subtitle}
                text={error}
            />
        </motion.div>
    );
};

export default SevereError;
