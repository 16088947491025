import React from "react";
import {TextField as MuiTextField} from "@mui/material";
import {TextFieldProps as MuiTextFieldProps} from "@mui/material/TextField/TextField";

export type TextFieldProps = MuiTextFieldProps & {
    name: string,
}

export default function TextField({name, ...restProps}: TextFieldProps) {
    return (
        <MuiTextField
            id={name}
            name={name}
            autoComplete={name}
            {...restProps}
        />
    );
}
