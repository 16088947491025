import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {AddLogoResponse, AddNewsLogoRequest} from "./Request/AddLogo";
import {NewsRequest, NewsResponseItem, PutNewsResponseItem} from "./Request/News";
import {PutCardResponseItem} from "./Request/Cards";

const newsApi = Api.injectEndpoints({
    endpoints: build => ({
        postNews: build.mutation< NewsResponseItem, [string, Partial<NewsResponseItem>]>({
            query: ([id, news]) => ({
                url: '/api/news',
                method: 'POST',
                body: JSON.stringify({...news, organisation: id})
            })
        }),

        addNewsLogo: build.mutation<AddLogoResponse, AddNewsLogoRequest>({
            query: ({ news_id, upload_id }) => ({
                url: `/api/news/${news_id}/add-newslogo`,
                method: 'POST',
                body: JSON.stringify({ file: upload_id })
            })
        }),

        deleteNews: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/news/${ id }`,
                method: 'DELETE'
            })
        }),
        getNewsItem: build.query<NewsResponseItem, NewsRequest>({
            query: ({ id }) => (`/api/news/${ id }`),
        }),
        putNews: build.mutation<void, Partial<PutNewsResponseItem>>({
            query: (news) => ({
                url:`/api/news/${ news.id }`,
                method: 'PUT',
                body: JSON.stringify(news)
            })
        }),
    }),
});

export const {
    usePostNewsMutation,
    useAddNewsLogoMutation,
    useDeleteNewsMutation,
    useGetNewsItemQuery,
    usePutNewsMutation
} = newsApi;
export default newsApi;