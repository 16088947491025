import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Shared/Form/Button';
import Form from '../../Shared/Form/Form';
import Input from '../../Shared/Form/Input';
import Title from '../../Shared/Title';
import SidePanelWrapper from './../SidePanelWrapper';
import { Strings } from "../../../Strings/nl";
import DigipasLogoWidePNG from "../../../Assets/digipaslogowide.png";
import { useForgotPasswordMutation } from "../../../Redux/Api/Auth";
import { useAppSelector } from "../../../Redux/store";
import { Hydra } from "../../../Utils/Hydra";
import Success from "../../Shared/Form/Success";
import Error from "../../Shared/Form/Error";
import BusyArea from "../../Shared/BusyArea";
import LanguageDropdown from '../../Shared/LanguageDropdown';
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const logoutError = useAppSelector((s) => s.auth.logoutReason);

    const [forgotPassword, setForgotPassword] = useForgotPasswordMutation();
    const [error, setError] = useState<string | undefined>(logoutError);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1].toLowerCase();
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];

        if (validLanguages.includes(currentLang)) {
            i18n.changeLanguage(currentLang).then(() => {
            });
        }
    }, [i18n]);

    const submit = async (data: { email: string; language: string }) => {
        if (busy) return;

        setBusy(true);
        setSuccessMessage('');
        setError('');

        const requestData = {
            email: data.email,
            language: i18n.language
        };

        try {
            await forgotPassword(requestData).unwrap();
            setSuccessMessage(t("forgotPassword:mailSend") + data.email);
        } catch (e) {
            setError(t("forgotPassword:error") || "");
        }
        setBusy(false);
    };

    return (
        <>
            <SidePanelWrapper>
                <img src={DigipasLogoWidePNG} className="w-[40rem] mb-16 px-5 mt-16" />
                <Success text={successMessage} />
                <Error text={error} />
                <Title text={t("forgotPassword:forgotPassword")} subtitle={t("forgotPassword:forgotPasswordPrefix") || ""} />
                <BusyArea busy={busy}>
                    <LanguageDropdown style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }} />
                    <Form submit={submit}>
                        <Input label={t("forgotPassword:email")} id='email' />
                        <div className='flex gap-4 flex-wrap'>
                            <Button title={t("forgotPassword:reset")} />
                            <Button
                                icon={faArrowLeft}
                                nosubmit
                                action={() => navigate(`/${i18n.language}/login`)}
                                title={t("forgotPassword:back")}
                            />
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    );
};

export default ForgotPasswordPage;
