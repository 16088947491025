
import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "../../Assets/i18n/en.json";
import translationNL from "../../Assets/i18n/nl.json";
import translationES from "../../Assets/i18n/es.json";
import translationFR from "../../Assets/i18n/fr.json";
import translationDE from "../../Assets/i18n/de.json";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    nl: {
        translation: translationNL,
    },
    es: {
        translation: translationES,
    },
    de: {
        translation: translationDE,
    },
    fr: {
        translation: translationFR,
    }
};

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng:"nl", //default language
        keySeparator: ':',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;