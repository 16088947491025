import React, { useEffect, useRef, useState } from "react";
import { Style, VictoryAxis, VictoryChart, VictoryLegend, VictoryLine, VictoryTooltip,VictoryLabel, TextSize, Background  } from "victory";
import { useTranslation } from "react-i18next";
import { SpatialTrackingSharp } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import LoadingPage from '../../LoadingPage'

export interface HourGraphProps {
    data: number[][];
    todate: Date;
    fromdate: Date;
}

const HourGraph = (props: HourGraphProps) => {
    const [loading, setLoading] = useState(false); // Nieuwe state voor het laden
    const [graphHolderRefWidth, setGraphHolderRefWidth] = useState(200);
    const [localMax, setLocalMax] = useState(0);
    const graphHolderRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const [graphLabels, setGraphLabels] = useState(new Array(15).fill(0));
    const [activeLine, setActiveLine] = useState<number | null>(null);

    const colors = {
        'check': '#0175FF',
        'checkin': '#00C45E',
        'checkout': '#FF0058'
    };

    let [days, setDays] = useState<number>(1);
    let [hlabel, setHlabel] = useState<Intl.DateTimeFormatOptions>({ day: 'numeric', month: 'short' });
    let [firstdays, setfirstdays] = useState<number>(1);
    let [value, setValue] = useState<number[]>([]);

    useEffect(() => {
        setLoading(true);
        const updateGraphParameters = () => {
            const dayCount = props.data[1].length - 1;
            let days = 1;
            let hlabel: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
            let firstdays = 1;
            let value: number[] = [];
    
            if (dayCount >= 365) {

                const currentYear = new Date(props.todate).getFullYear();
                const firstDayOfYear = new Date(currentYear, 1, 0);
                const currentDate = new Date(props.todate);
                let tempDate = new Date(props.fromdate);
                
                while (tempDate.getFullYear() <= props.todate.getFullYear()) {

                    const year = tempDate.getFullYear();
                    const firstDayOfYear = new Date(year, 0, 1);
                    const lastDayOfYear = new Date(year, 11, 31);
                    const daysInYear = Math.ceil((lastDayOfYear.getTime() - firstDayOfYear.getTime()) / (1000 * 3600 * 24));
                    
                    value.push(daysInYear + 1);
                    tempDate.setFullYear(year + 1);
                }

                days = 365;
                hlabel = { year: 'numeric' };
                firstdays = (Math.ceil((currentDate.getTime() - firstDayOfYear.getTime()) / (1000 * 3600 * 24)));

            } else if (dayCount >= 30) {

                const currentYear = new Date(props.todate).getFullYear();
                const currentMonth = new Date(props.todate).getMonth();
                const firstDayOfMonth = new Date(currentYear, currentMonth, 0);
                const currentDate = new Date(props.todate);

                const toDate = new Date(props.todate);    

                let tempDate = new Date(props.fromdate);

                while (tempDate.getFullYear() < toDate.getFullYear() || (tempDate.getFullYear() === toDate.getFullYear() && tempDate.getMonth() <= toDate.getMonth())) {
                    value.push(new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate());
                    tempDate.setMonth(tempDate.getMonth() + 1);
                }        

                days = 31;
                hlabel = { month: 'short', year: 'numeric' };
                firstdays = (Math.ceil((currentDate.getTime() - firstDayOfMonth.getTime()) / (1000 * 3600 * 24)));

            } else if (dayCount >= 14) {

                days = 7;
                hlabel = { day: 'numeric', month: 'short' };
                firstdays = 7;
            }
    
            setDays(days);
            setHlabel(hlabel);
            setfirstdays(firstdays);
            setValue(value)
        };

        updateGraphParameters();
    }, [props.data]);

    useEffect(() => {
        const calculateLocalMax = () => {
            let highestValue = 0;
            for (let i = 0; i < 3; i++) {
                const dataResult = data(i);
                const maxValue = Math.max(...dataResult);
                highestValue = Math.max(highestValue, maxValue);
            }
            setLocalMax(highestValue * 1.1);
            setLoading(false)
        };
    
        calculateLocalMax();
    }, [props.fromdate, props.todate, props.data, days,value]);
    
    function data(number: number) { 
        let results: number[] = [];
        const dayCount = props.data[1].length - 2;

        if (props && props.data && days && value) {  
            for (let i = 0; i <= (Math.ceil(dayCount / days)); i++) {
                let sum = 0;
                for (let e = 0; e < (i === 0 ? firstdays : days === 30 ? value[i] : days); e++) {
                    if(days === 30 || days == 365){
                        let maxvalue = 0
                        if(i > 1){
                            for (let j = 1; j < i; j++) {
                                if(value[j]){
                                    maxvalue += value[j];
                                }
                            }
                        }
                        if (props.data[number][dayCount - (i === 0 ? e : firstdays + maxvalue + e)]) {
                            sum += props.data[number][dayCount - (i === 0 ? e : firstdays + maxvalue + e)];
                        }
                    } 
                    else{
                        if(props.data[number][(dayCount - (i === 0 ? e : firstdays + ((i -1) * days + e)))]){
                            sum += props.data[number][dayCount - (i === 0 ? e : firstdays + ((i -1) * days + e))];
                        }
                    }
                }
                results.unshift(sum); 
            } 
            if(days == 365){
                for (let i = 14 < dayCount / 365 ? dayCount / 365 : 14 - results.length; i >= 0; i--) {
                    results.unshift(0)
                }
            }else{
                for (let i = 14 - results.length; i >= 0; i--) {
                    results.unshift(0)
                }
            }
        }
        return results.flat();        
    }
    
    const checks = data(0);
    const checkIns = data(1);
    const checkOuts = data(2);

    useEffect(() => {
        checkIns.length >= checks.length ? setGraphLabels(checkIns) : setGraphLabels(checks)
    }, [checks, checkIns, checkOuts])
    
    useEffect(() => {
        switch (activeLine) {
            case 0:
                setGraphLabels(checks)
                break;
            case 1:
                setGraphLabels(checkIns)
                break;
            case 2:
                setGraphLabels(checkOuts)
                break;
            default:
                checkIns.length >= checks.length ? setGraphLabels(checkIns) : setGraphLabels(checks)
                break;
        }
    }, [activeLine, checks, checkIns, checkOuts])    

    useEffect(() => {
        if (localMax) {
            resize();
        }
    }, [localMax]);

    const resize = () => {
        if (!graphHolderRef.current) return
        setGraphHolderRefWidth((graphHolderRef.current?.getBoundingClientRect().width || 200))
    }

    useEffect(() => {
        resize()
        window.addEventListener('resize', resize)
        return () => window.removeEventListener('resize', resize)
    }, [graphHolderRef])

    const dates = () => {
        const datesArray = [];
        if (days && hlabel) {
            for (let i = 13; i >= 0; i--) {
                let todate = new Date(props.todate);
                todate.setDate(todate.getDate() - i * days);
                datesArray.push(todate.toLocaleDateString('nl-BE', hlabel));
            }
        }
        return datesArray;
    }

    const legendData = [
        {
            key: 0,
            name: String(t('overview:check')),
            symbol: { fill: colors['check'], cursor: 'pointer', size: 6 },
        },
        {
            key: 1,
            name: String(t('overview:checkin')),
            symbol: { fill: colors['checkin'], cursor: 'pointer', size: 6 },
        },
        {
            key: 2,
            name: String(t('overview:scanout')),
            symbol: { fill: colors['checkout'], cursor: 'pointer', size: 6},
        },
    ]

    const LegendLabel = (props: any) => {
        const { datum, x, y } = props
        const isActive = activeLine === datum.key

        const textX = x - 20;
        const textY = y + 5;
        const fontSize = 14;
        const fill = '#5B6C79';
        const padding = 30;

        const measureTextWidth = (text: string, fontSize: number) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            //@ts-ignore
            context.font = `${fontSize}px sans-serif`;
            //@ts-ignore
            return context.measureText(text).width;
        };

        const textWidth = measureTextWidth(datum.name, fontSize);
        const rectWidth = textWidth + padding;
        return (
            <svg>
                {isActive && (
                    <rect
                        rx={20}
                        x={textX + 8}
                        y={8}
                        width={rectWidth}
                        height={25}
                        fill="#F4F8FB"
                    />
                )}
                 <text  
                    x={x}
                    y={textY}
                    fontSize={fontSize}
                    fill={fill}
                >
                    {datum.name}
                </text>
            </svg>
        );
    }

    return (
        <div style={{border: '1px solid #D7E3F2', background: 'white', minHeight: '502px', maxHeight: '502px', display: 'flex', flexDirection: 'column', borderRadius: '2px'}}>
            <h1  style={{fontSize: '16px'}} className='font-bold px-5 p-4'>{t('overview:NumberOfScans')}</h1>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}ref={graphHolderRef}>
            {loading ? (
                <LoadingPage/>
            ) : (
            <div className='flex h-full w-full p-4 'style={{borderTop: '1px solid #D7E3F2', flex: 1,alignItems: 'center'}}>
                <React.Fragment>
                    {localMax ? (
                        <VictoryChart height={350} width={graphHolderRefWidth} padding={45} >
                            <VictoryLegend
                                orientation='horizontal'
                                symbolSpacer={20}
                                gutter={30}
                                data={legendData}
                                labelComponent={<LegendLabel />}
                                x={graphHolderRefWidth - 330} 
                                events={[{
                                    target: 'data',
                                    eventHandlers: {
                                        onClick: (_, { datum }) => {
                                            console.log(datum)
                                            activeLine === datum.key ? setActiveLine(null) : setActiveLine(datum.key)
                                        }
                                    }
                                }]}
                            />
                            <VictoryAxis
                                tickValues={dates()}
                                    tickFormat={(date) => {
                                    const lines = date.split(" ");
                                    return lines.map((line: string, index: Number) => <tspan dy={index === 0 ? 0 : "1.4em"}>{line}</tspan>);
                                    }}
                                tickCount={14}
                                domain={[0, 14]}
                                style={{
                                    tickLabels: { fontSize: 14, padding: 10,fill: '#5B6C79', fontFamily: 'Inter' },
                                    axis: { stroke: '#d3d3d3' },
                                }}
                                    
                            />

                            <VictoryAxis
                                domain={[0, localMax * 1.33]}
                                dependentAxis
                                style={{
                                    tickLabels: { fontSize: 14, padding: 10,fill: '#5B6C79', fontFamily: 'Inter' },
                                    axis: { stroke: '#d3d3d3' },
                                }}
                            />

                            <VictoryLine
                                data={checks}
                                style={{
                                    data: {
                                        cursor: 'pointer',
                                        stroke: colors['check'],
                                        strokeWidth: activeLine === 0 ? 4 : activeLine === null ? 2 : 0,
                                    }
                                }}
                                events={[{
                                    target: 'data',
                                    eventHandlers: {
                                        onClick: () => {
                                            activeLine !== 0 ? setActiveLine(0) : setActiveLine(null)
                                        }
                                    }
                                }]}
                            />

                            <VictoryLine
                                data={checkOuts}
                                style={{
                                    data: {
                                        cursor: 'pointer',
                                        stroke: colors['checkout'],
                                        strokeWidth: activeLine === 2 ? 4 : activeLine === null ? 2 : 0,
                                    }
                                }}
                                events={[{
                                    target: 'data',
                                    eventHandlers: {
                                        onClick: () => {
                                            activeLine !== 2 ? setActiveLine(2) : setActiveLine(null)
                                        }
                                    }
                                }]}
                            />

                            <VictoryLine
                                data={checkIns}
                                style={{
                                    data: {
                                        cursor: 'pointer',
                                        stroke: colors['checkin'],
                                        strokeWidth: activeLine === 1 ? 4 : activeLine === null ? 2 : 0,
                                        opacity: activeLine !== null && activeLine !== 1 ? 0.25 : 1
                                    }
                                }}
                                events={[{
                                    target: 'data',
                                    eventHandlers: {
                                        onClick: () => {
                                            activeLine !== 1 ? setActiveLine(1) : setActiveLine(null)
                                        }
                                    }
                                }]}

                            />
                            <VictoryLine
                                style={{
                                    parent: { display: 'none' }
                                }}
                                data={new Array(15).fill(localMax * 1.1)}
                                labels={graphLabels.map((num) => (num === 0 ? '' : num))}
                            /> 
                        </VictoryChart>
                    ) : (
                        <VictoryChart width={graphHolderRefWidth} padding={45}>
                            <VictoryLegend
                                orientation='horizontal'
                                symbolSpacer={10}
                                gutter={25}
                                data={legendData}
                                labelComponent={<LegendLabel />}
                            />
                            <VictoryAxis
                                tickValues={dates()}
                                tickCount={14}
                                domain={[0, 14]}
                            />
                            <VictoryAxis
                                domain={[0, 15 * 1.33]}
                                dependentAxis
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: 'var(--color-accent)' }
                                }}
                                data={new Array(15).fill(15 * 1.2)}
                            />
                        </VictoryChart>
                    )}
                </React.Fragment>
                    </div>
            )}
            </div>
        </div>
    );
};

export default HourGraph;