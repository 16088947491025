import * as React from "react";
import {LoginStatus} from "../../Redux/State/Auth";
import {Navigate} from "react-router-dom";
import { useAppSelector } from "../../Redux/store";
import { JWT } from "../../Utils/JWT";
import { useTranslation } from "react-i18next";

export interface PrivateRouteProps {
    children: React.ReactElement | Array<React.ReactElement>
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { i18n } = useTranslation();
    const loginStatus = useAppSelector(state => state.auth.status);
    const exp = JWT.read<number>('exp');

    if (!exp || Date.now() >= exp * 1000 || loginStatus === LoginStatus.NOT_LOGGED_IN) {
        localStorage.removeItem('token');
        window.location.pathname = `/${i18n.language}/login`;
    }

    return <>{ children }</>
}

export default PrivateRoute;
