import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {CreateUserRequest, RegiserUserRequest, UserResponse, UsersResponse} from './Request/User';

const userApi = Api.injectEndpoints({
    endpoints: build => ({
        getUsers: build.query<UsersResponse, void>({
            query: () => (`/api/users`),
            transformResponse: Hydra.MultiResponseTransformer
        }),
        getUser: build.query<UserResponse, string>({
            query: (id) => (`/api/users/${ id }`)
        }),
        createUser: build.mutation<UserResponse, [string, Partial<CreateUserRequest>]>({
            query: (request) => ({
                url: `/api/users`,
                method: 'POST',
                body: JSON.stringify(request)
            })
        }),
        putUser: build.mutation<UserResponse, [string, Partial<RegiserUserRequest>]>({
            query: ([id, user]) => ({
                url: `/api/users/${ id }`,
                method: 'PUT',
                body: JSON.stringify(user)
            })
        }),
        deleteUser: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/users/${ id }`,
                method: 'DELETE'
            })
        }),
        registerUser: build.mutation<void, RegiserUserRequest>({
            query: (request) => ({
                url: '/api/user/register',
                method: 'POST',
                body: JSON.stringify(request)
            })
        }),
    }),
});

export const { useGetUsersQuery, useGetUserQuery, useCreateUserMutation, usePutUserMutation, useDeleteUserMutation, useRegisterUserMutation } = userApi;
export default userApi;