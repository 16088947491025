import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Title from '../Title';
import Button from './Button';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onClose: (confirmed: boolean) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, message, onClose }) => {
    const formatMessage = (msg: string) => {
        const dateRegex = /\b[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} [A-Za-z+0-9:() ]+\b/;

        const cleanedMessage = msg.replace(/\(.*?\)/g, '').trim();

        return cleanedMessage.replace(dateRegex, (match) => {
            const dateObj = new Date(match);
            const formattedDate = dateObj.toLocaleDateString('en-CA');
            return `<strong>${formattedDate}</strong>`;
        });
    };

    const { t } = useTranslation();

    return (
        <AnimatePresence>
            {open && (
                <div className="fixed flex items-center justify-center left-0 top-0 w-full h-screen z-[1000000]">

                        <motion.div
                            initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            className='flex flex-col z-50 max-w-[90vw] md:max-w-[50vw] bg-light-200'>
                            <div className='flex flex-col m-20'>
                                <Title text={title + '!'} />
                                <div className='mt-4'>
                                    <p dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
                                </div>
                                <div className='flex gap-4 mt-8'>
                                    <Button nosubmit title={t('cards:list:yes')} action={ () => onClose(true) }/>
                                    <Button secondary nosubmit title={t('cards:list:no')} action={ () => onClose(false) }/>
                                </div>
                            </div>
                        </motion.div>

                    <motion.div
                        key="background-div"
                        onClick={() => onClose(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        className="fixed left-0 top-0 w-screen h-screen bg-black"
                    />
                </div>
            )}
        </AnimatePresence>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
