import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HTMLMotionProps, motion } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { TableContext } from './Table'

export interface ColumnProps extends HTMLMotionProps<"td"> {
    name: any,
    type?: string,
    initialSort?: 'up' | 'down'
}

const Column = (props: ColumnProps) => {

    const ctx = useContext(TableContext)

    const { children, name, type, initialSort, ...restProps} = props

    useEffect(() => {
        ctx.setColumn(name, type || 'default')
        if (initialSort) {
            ctx.setSort([name, initialSort])
        }
    }, [])

    const sort = () => {
        ctx.setSort([name,  ctx.sort[0] === name && ctx.sort[1] === 'down' ? 'up' : 'down'])
    }

    return (
        <motion.td onClick={ sort } {...restProps} className={`cursor-pointer whitespace-nowrap select-none ${ type === 'cut' && 'max-w-[5rem] overflow-hidden text-ellipsis' }`}>
            <div className='flex'>
                { name }
                <motion.div animate={{ rotate: ctx.sort[1] === 'down' ? '0deg' : '180deg' }} className='w-4 h-4 ml-2 mt-1 flex items-center justify-center'>
                    { ctx.sort[0] === name && <FontAwesomeIcon className='text-xs' icon={ faChevronDown } /> }
                </motion.div>
            </div>
        </motion.td>
    )
}

export default Column;
