import * as React from 'react';
import {GridOverlay} from '@mui/x-data-grid';
import {createStyles, makeStyles} from '@mui/styles';
import {createTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            label: {
                marginTop: theme.spacing(8),
            },
        }),
    { defaultTheme },
);

export default function DataGridNoRowsOverlay() {
    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <GridOverlay>
            <div className={classes.label}>{t('datagrid:noData')}</div>
        </GridOverlay>
    );
}
