import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganisationSettings, ThemeDefinition } from '../Api/Request/Organisations'

export interface OrganisationState {
    id?: string,
    name?: string,
    type?: string,
    settings?: Partial<OrganisationSettings>
}

const initialState: OrganisationState = {
    settings: {
        new: true
    }
}

const Organisation = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        setOrganisation(state, action: PayloadAction<OrganisationState>) {
            state.id = action.payload.id
            state.name = action.payload.name
            state.type = action.payload.type
            state.settings = action.payload.settings
        },
        putSettingsLocal(state, action: PayloadAction<Partial<OrganisationSettings>>) {
            if(!state.settings) state.settings = {}
            state.settings = {
                ...state.settings,
                ...action.payload
            }
        },
        putThemeLocal(state, action: PayloadAction<ThemeDefinition['colors']>) {
            if(!state.settings) state.settings = {}
            if(!state.settings.theme) state.settings.theme = {} as any
            if(!state.settings.theme!.colors) state.settings.theme!.colors = {} as any

            state.settings.theme!.colors = {
                ...state.settings.theme!.colors,
                ...action.payload
            }
        },
        putSocialsLocal: (state, action: PayloadAction<OrganisationSettings['socials']>) => {
            if(!state.settings) state.settings = {}
            if(!state.settings.socials) state.settings.socials = {} as any
            state.settings.socials = action.payload
        },
        putFeedLocal: (state, action: PayloadAction<OrganisationSettings['news']['feed']>) => {
            if(!state.settings) state.settings = {}
            if(!state.settings.news) state.settings.news = {} as any
            state.settings.news!.feed = action.payload
        }
    }
})

export const { setOrganisation, putThemeLocal, putSocialsLocal, putFeedLocal, putSettingsLocal } = Organisation.actions

export default Organisation.reducer