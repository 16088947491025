import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'

export interface SidePanelWrapperProps extends HTMLMotionProps<"div"> {

}

const SidePanelWrapper = (props: SidePanelWrapperProps) => {
    return (
        <>
            <motion.div {...props} initial={{ translateX: '-.5rem' }} animate={{ translateX: '0rem' }} className='w-screen xl:w-1/3 h-screen p-10 bg-light-200 overflow-y-scroll no-scrollbar'/>
            <style>{`
            .no-scrollbar {
                scrollbar-width: none;
                -ms-overflow-style: none;
            }
            .no-scrollbar::-webkit-scrollbar {
                display: none;
            }
            `}</style>

        </>
    )
}

export default SidePanelWrapper